import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { CoursesDataService } from '../courses/services/courses-data.service';
import { RazorpayAuthService } from '../../services/razorpay-auth.service';
import { Observable } from "rxjs";
import { UserProfile } from "../user-profile/models/models";
import { UserProfileRestService } from 'src/app/modules/user-profile/services/user-profile-rest.service';
import { RegistrationService } from "../registration/services/registration.service";
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { LocationStrategy, PlatformLocation } from '@angular/common';
import { LocalStorageService } from 'src/app/core/local-storage.service';
import Freecurrencyapi from '@everapi/freecurrencyapi-js';

@Component({
  selector: 'edu-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
  
  userProfile$: Observable<UserProfile>;
  arrOfItems: any = [];
  emptyCart: boolean;
  totalPrice: number = 0;
  totalDollar: number = 0;
  totalEuro: number = 0;
  totalGbp: number = 0;
  discountData: any = 0;
  afterDiscount: any;
  discountPrice: number;
  discountDollar: number;
  discountEuro: number;
  discountGbp: number;
  courses: any;
  rupeeToDollar: number = 0.012;
  rupeeToEuro: number = 0.011;
  rupeeToGbp: number = 0.0095;
  radioItems: Array<string> = ['Rupee', 'Dollar', 'Euro', 'Gbp'];
  model = { option: 'Rupee' };
  hostNameAsBool: boolean = true;

  loading: boolean = true;
  check: boolean = true;

  rzp1: any;
  userName: any;
  id = 390;
  useremail: any;
  usercontact: any;
  arrOfItemsId: any;
  arrOfItemsIdtemp = 44;
  price: any;
  dollar: any;
  euro: any;
  gbp: any;
  currencyCode = 'INR';
  amountForPaymentGateway: any;
  countries: any = [];
  currency: string = '';
  galleries: any = [];
  totalAmount: any;
  countryName: any;

  constructor(
    private courseDataService: CoursesDataService,
    private router: Router,
    private titleService: Title,
    private razorpayAuthService: RazorpayAuthService,
    private userProfileRestService: UserProfileRestService,
    private registrationService: RegistrationService,
    private location: Location,
    private locationStrategy: LocationStrategy,
    private platformLocation: PlatformLocation,
    private storageService: LocalStorageService,
  ) { }

  ngOnInit(): void {
    // getting currency conversion factor using freecurrencyapi
    this.getCurrencyExchange();

    // const protocol = this.platformLocation.protocol;
    const host = this.platformLocation.hostname;
    // this.amountForPaymentGateway = this.price;
    // const path = this.locationStrategy.getBaseHref();
    // const fullUrl = protocol + '//' + host + path;
    if (host.includes('localhost') || host.includes('stagingwebsite')) {
      this.hostNameAsBool = false;
    } else {
      this.hostNameAsBool = true;
    }

    this.titleService.setTitle("Checkout - 21CC Recruitment & Training");

    var storageValue = localStorage.getItem('shopping-cart');
    if (storageValue == '[]' || storageValue == null) {
      this.emptyCart = true;
      this.loading = false;
    }
    else {
      this.emptyCart = false;
      var item = localStorage.getItem('shopping-cart').slice(1, localStorage.getItem('shopping-cart').length - 1);
      this.arrOfItemsId = item.split(',');
      this.courseDataService.getCoursesData().subscribe((allCourses) => {

      // const localItems = localStorage.getItem('shopping-cart').slice(1, localStorage.getItem('shopping-cart').length - 1);
      // this.courseDataService.getUserCountryPricing(null, localItems).subscribe((userPrice: any) => {
      //   console.log(userPrice)
      //   this.countries = userPrice.countries;
      //   this.currency = userPrice.currency;
      //   this.galleries = userPrice.galleries;
      //   this.totalAmount = userPrice.totalAmount;
      //   this.countryName = userPrice.countryName;

      //   for (var i = 0; i < this.arrOfItemsId.length; i++) {
      //     var courseItem = allCourses.filter((x: any) => x?.id == this.arrOfItemsId[i])[0];
      //     this.arrOfItems.push(courseItem);
      //     if (this.arrOfItems[i]) {
      //       this.arrOfItems[i].check = true;
      //       let priceCurrent = this.arrOfItems[i]?.cost;
      //       if(isNaN(priceCurrent)) {
      //         priceCurrent = 0;
      //       }
      //       this.arrOfItems[i].price = priceCurrent
      //     }
      //   };
      // });

        this.courses = allCourses;
        for (var i = 0; i < this.arrOfItemsId.length; i++) {
          var courseItem = allCourses.filter((x: any) => x?.id == this.arrOfItemsId[i])[0];
          this.arrOfItems.push(courseItem);
          console.log(this.arrOfItems);
          if (this.arrOfItems[i]) {
            this.arrOfItems[i].check = true;
            let priceCurrent = this.arrOfItems[i]?.cost;
            if(isNaN(priceCurrent)) {
              priceCurrent = 0;
            }
            this.arrOfItems[i].dollarPrice = priceCurrent * this.rupeeToDollar;
            this.arrOfItems[i].euroPrice = priceCurrent * this.rupeeToEuro;
            this.arrOfItems[i].gbpPrice = priceCurrent * this.rupeeToGbp;
          }
        };
        this.totalCart();
        this.loading = false;
      });
    }
  }

  onSelectChange(selectedValue: string) {
    this.courseDataService.getCoursesData().subscribe((allCourses) => {
      const localItems = localStorage.getItem('shopping-cart').slice(1, localStorage.getItem('shopping-cart').length - 1);
      this.courseDataService.getUserCountryPricing(selectedValue, localItems).subscribe((userPrice: any) => {
        this.countries = userPrice.countries;
        this.currency = userPrice.currency;
        this.galleries = userPrice.galleries;
        this.totalAmount = userPrice.totalAmount;
        this.countryName = userPrice.countryName;

        this.arrOfItemsId = localItems.split(',');

        this.arrOfItems = [];

        for (var i = 0; i < this.arrOfItemsId.length; i++) {
          var courseItem = allCourses.filter((x: any) => x?.id == this.arrOfItemsId[i])[0];
          const matchingGallery = this.galleries.find(gallery => gallery.galleryId === courseItem.id);
          if (matchingGallery) {
            courseItem.cost = matchingGallery.cost;
          }
          this.arrOfItems.push(courseItem);
          if (this.arrOfItems[i]) {
            this.arrOfItems[i].check = true;
            let priceCurrent = this.arrOfItems[i]?.cost;
            if(isNaN(priceCurrent)) {
              priceCurrent = 0;
            }
            this.arrOfItems[i].price = priceCurrent
          }
        };
      });
        this.totalCart();
        this.loading = false;
      });
  }

  checkall() {
    this.check = !this.check
    for (var i = 0; i < this.arrOfItems.length; i++) {
      this.arrOfItems[i].check = this.check;
    }
    this.totalCart();
  }

  totalCart() {
    this.totalPrice = 0;
    this.totalDollar = 0;
    this.totalEuro = 0;
    this.totalGbp = 0;
    if (this.arrOfItems) {
      for (let i = 0; i < this.arrOfItems.length; i++) {
        if (this.arrOfItems[i]?.check) {
          let priceCurrent: any, dollarPrice: any, euroPrice: any, gbpPrice: any;
          priceCurrent = this.arrOfItems[i]?.cost;
          dollarPrice = this.arrOfItems[i]?.dollarPrice;
          euroPrice = this.arrOfItems[i]?.euroPrice;
          gbpPrice = this.arrOfItems[i]?.gbpPrice;
          if(isNaN(priceCurrent)) {
            priceCurrent = 0;
          }
          if(isNaN(dollarPrice)) {
            dollarPrice = 0;
          }
          if(isNaN(euroPrice)) {
            euroPrice = 0;
          }
          if(isNaN(gbpPrice)) {
            gbpPrice = 0;
          }
          this.totalPrice += Number(priceCurrent);
          this.totalDollar += Number(dollarPrice);
          this.totalEuro += Number(euroPrice);
          this.totalGbp += Number(gbpPrice);
        }
      }
    }

    this.discountPrice = this.totalPrice * this.discountData * 0.01;
    this.discountDollar = this.totalDollar * this.discountData * 0.01;
    this.discountEuro = this.totalEuro * this.discountData * 0.01;
    this.discountGbp = this.totalGbp * this.discountData * 0.01;

    this.price = (this.totalPrice - this.discountPrice).toFixed(2);
    this.dollar = this.totalDollar - this.discountDollar;
    this.euro = this.totalEuro - this.discountEuro;
    this.gbp = this.totalGbp - this.discountGbp;
    this.onRadioButtonClick();
  }

  options = {
    "key": "",
    "secret": "",
    "amount": 0,
    "currency": "INR",
    "name": "Acme Corp",
    "description": "Test Transaction",
    "image": "assets/images/logo.svg",
    "order_id": "",
    // "callback_url": "https://eneqd3r9zrjok.x.pipedream.net/",
    "prefill": {
      "name": "",
      "email": "",
      "contact": ""
    },
    "notes": {
      "address": "Razorpay Corporate Office"
    },
    "theme": {
      "color": "#3399cc"
    },
    "handler": function (response) {
      this.handle_response(); //does not work as cannot identify 'this'
      alert('Payment successfully completed and id is' + response.razorpay_payment_id);
    }.bind(this)
  };

  pay() {
    this.loading = true;
    const authDetails = JSON.parse(this.storageService.retrieveFromLocalStorage('authDetails'));
    if (authDetails) {
      this.userProfileRestService.getUserProfile().subscribe(data => {
        this.userName = data.user.firstName;
        this.useremail = data.user.email;
        this.usercontact = data.user.telephone;
      });
      this.courseDataService.getDiscount().subscribe((data: any) => {
        this.discountData = data ? data.amount : 0;
      });
      if (this.hostNameAsBool == true) {
        this.options.key = "rzp_live_6fBtcEXoe2DS9O";
        this.options.secret = "H6CBbjMpb6dd4y3PQUqzuin3";
      } else {
        this.options.key = "rzp_test_UR379KwDDEQNfY";
        this.options.secret = "Suc7ORI1gwJTqxAwwDe8g5Ul";
      }


      this.options.name = "21CC Education";
      this.options.prefill.email = this.useremail;
      this.options.prefill.contact = this.usercontact;
      // this.options.amount = 100;

      this.options.amount = this.amountForPaymentGateway * 100;

      this.options.currency = this.currencyCode;
      this.rzp1 = new this.razorpayAuthService.nativeWindow.Razorpay(this.options);
      this.rzp1.open();
      this.rzp1.on('payment.failed', function (response) {
        alert(response.error.code);
        alert(response.error.description);
        alert(response.error.source);
        alert(response.error.step);
        alert(response.error.reason);
        alert(response.error.metadata.order_id);
        alert(response.error.metadata.payment_id);
      });
    } else {
      this.router.navigate(['registration/start']);
    }
  }

  onRadioButtonClick() {
    if (this.model.option == 'Dollar') {
      this.currencyCode = 'USD';
      this.amountForPaymentGateway = this.dollar;
    } else if (this.model.option == 'Euro') {
      this.currencyCode = 'EUR';
      this.amountForPaymentGateway = (this.euro).toFixed(2);
    } else if (this.model.option == 'Gbp') {
      this.currencyCode = 'GBP';
      this.amountForPaymentGateway = (this.gbp).toFixed(2);
    } else {
      this.currencyCode = 'INR';
      this.amountForPaymentGateway = this.price;
    }
  }

  handle_response() {
    this.loading = true;
    for (var i = 0; i <= this.arrOfItemsId.length; i++) {
      this.registrationService.registerCourseForUser(this.arrOfItemsId[i])
        .pipe(
          catchError((e) => {
            console.log(JSON.stringify(e.error));
            console.log(JSON.stringify(e));
            console.log(JSON.stringify(e.status));
            this.loading = false;
            return throwError(e);
            
          })
        )
        .subscribe((res) => {
          console.log('HTTP response', res);
          this.loading = false;
        },
          (err) => {
            console.log('HTTP Error', err);
            this.loading = false;
          },
          () => {
            console.log('HTTP request completed.');
            this.loading = false;
          }
        );
    }

    // this.router.navigate([`/my-courses`]);
    localStorage.removeItem('shopping-cart');
    localStorage.removeItem('shopping-cart-bundles');
    window.location.href = `/my-courses`;
    this.loading = false;
  }

  // removeFromCartLocalStorage() {
  //   var existingEntries = JSON.parse(localStorage.getItem("shopping-cart"));
  //   for(i=0;i< this.arrOfItems.length; i++){
  //     if(this.arrOfItems[i].check == true){
  //       var index = existingEntries.indexOf(this.arrOfItems[i].id);
  //       existingEntries.splice(index, 1);
  //     }
  //   }
  //   if(!existingEntries.length) {
  //     this.emptyCart = true;
  //     localStorage.removeItem('shopping-cart');
  //     localStorage.removeItem('shopping-cart-bundles');
  //     localStorage.removeItem('shopping_cart_without_bundle_id');
  //     this.courseDataService.callComponentMethod();

  //   }
  //   else {
  //     localStorage.setItem("shopping-cart", JSON.stringify(existingEntries));
  //     this.courseDataService.callComponentMethod();
  //     this.arrOfItems = []
  //     for (var i = 0; i < existingEntries.length; i++) {
  //       this.arrOfItems.push(this.courses.filter((x: any) => x?.id == existingEntries[i])[0]);
  //     };
  //     this.totalCart();
  //   }
  // }

  removeFromCartLocalStorage(item: any) {
    var existingEntries = JSON.parse(localStorage.getItem("shopping-cart"));
    if (existingEntries && existingEntries.length > 0) {
      var index = existingEntries.indexOf(item.id);
      if (index !== -1) {
        existingEntries.splice(index, 1);
      }
      localStorage.setItem("shopping-cart", JSON.stringify(existingEntries));
      this.courseDataService.callComponentMethod();
    }

    this.arrOfItems = this.arrOfItems.filter((x: any) => x.id !== item.id);
    this.totalCart();

    if (!existingEntries || existingEntries.length === 0) {
      this.emptyCart = true;
      localStorage.removeItem('shopping-cart');
      localStorage.removeItem('shopping-cart-bundles');
      localStorage.removeItem('shopping_cart_without_bundle_id');
      this.courseDataService.callComponentMethod();
    }
  }

  getCurrencyExchange() {
    const freecurrencyapi = new Freecurrencyapi('fca_live_3TNFRXymzge4K9r91oPuV697hMPVhHwSgAJxuIvo');
    freecurrencyapi.latest({
      base_currency: 'INR',
      currencies: ['EUR', 'USD', 'GBP']
    }).then(response => {
        this.rupeeToDollar = response.data.USD.toFixed(4);
        this.rupeeToEuro = response.data.EUR.toFixed(4);
        this.rupeeToGbp = response.data.GBP.toFixed(4);
    });
  }
}
