<edu-default-layout>
    <edu-spinner *ngIf="loading"></edu-spinner>
    <section class="container-fluid" style="margin-top: 100px; margin-bottom: 100px;">
        <div *ngIf="!showUpgrade">
            <h2 class="planHeading">Select your plan</h2>
            <p class="planPara plan-mt-40 plan-border-box" style="text-align: center;">
                <!-- With one yearly plans your team will have access to all team packages.  -->
                With one plan you can choose all packages for your team.
            </p>
        <p class="planPara plan-border-box" style="text-align: center;">
            Want to view list of packages? <a routerLink="/library" style="color:#189CD8;   text-decoration-color: #189CD8;">Team packages page</a></p>
        </div>
        <div *ngIf="showUpgrade">
            <h2 class="planHeading">Upgrade your plan</h2>
            <!-- <h2 class="planHeading">Upgrade your plan</h2> -->
            <p class="planPara plan-mt-40 plan-border-box" style="text-align: center;">
                <!-- With one yearly plans your team will have access to all team packages. -->
                With one plan you can choose all packages for your team.
            </p>                   
        </div>

        <!-- <div class="boxSect">
            <div class="boxcontainer plan-mt-40">
                <div *ngFor="let plan of planDetails; let i = index">
                    <div [ngStyle]="{
                        'background-color': (plan.userPlanStatus === 'Purchased') ? '#F1FFF0' : '#fff',
                        'border': (plan.userPlanStatus === 'Purchased') ? '1px solid #3DA435' : '1px solid #D2D2D2'}"
                          class="box">
                        <h3 class="planTitle"> {{plan.planTitle}}</h3>
                   
                        <p class="planPrice">&#8377;{{plan.planPrice}}</p>
                        <p class="planPara">yearly</p>
             
                        <p class="planPara plan-mt-35">Up to <b>{{plan.planLicences}}</b> licences</p>
                
                        <div class="plan-mt-50" *ngIf="plan.userPlanStatus == 'Purchased'">
                            <p class="planPara" style="color:#3DA435;"> Current plan</p>
                        </div>
                        <div class="plan-mt-50" *ngIf="plan.userPlanStatus != 'Purchased'">
                            <button class="btn-start" (click)="navigateToRegistration(plan)"> Get started</button>
                        </div>
            
                    </div>
                </div>
    
                
              </div>
        </div> -->

        <div class="grid-container">
            <main class="grid">
              <article *ngFor="let plan of planDetails; let i = index" class="box" [ngStyle]="{
                'background-color': (plan.userPlanStatus === 'Purchased') ? '#F1FFF0' : '#fff',
                'border': (plan.userPlanStatus === 'Purchased') ? '1px solid #3DA435' : '1px solid #D2D2D2'}">
                <div class="text">
                    <h3 class="planTitle">{{plan.planTitle}}</h3>
                   
                    <p class="planPrice">{{convertCurrencyToIndianFormat(plan.planPrice)}} <span class="planPara">annually</span></p>
                    <!-- <p class="planPara">yearly</p> -->
         
                    <p class="planPara plan-mt-35">up to <strong class="plan-license">{{plan.planLicences}}</strong> licences</p>
            
                    <div class="plan-mt-50" *ngIf="plan.userPlanStatus == 'Purchased'">
                        <p class="planPara" style="color:#3DA435;"> Current plan</p>
                    </div>
                    <div class="plan-mt-50" *ngIf="plan.userPlanStatus != 'Purchased'">
                        <button class="btn-start" (click)="navigateToRegistration(plan)"> Get started</button>
                    </div>
                </div>
                </article>
            </main>
          </div>

    </section>
</edu-default-layout>

