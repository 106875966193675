import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'edu-career-contact',
  templateUrl: './career-contact.component.html',
  styleUrls: ['./career-contact.component.scss']
})
export class CareerContactComponent implements OnInit {

  tabName: string = "All";
  model: any = {};
  jobList: any = ['Writer', 'Backend Developer', 'Frontend Developer', 'Instructional Designer (Articulate 360 Developer)', 'Game Developer', 'Project Manager', 'Marketing Executive', 'VO Artist', 'Illustrator'];

  constructor(private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle("Careers - 21CC Recruitment & Training");
  }

  onSubmit() {
  }
  
  assignName(name: string){
    this.tabName = name;
  }
}
