import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CoursesDataService } from '../courses/services/courses-data.service';
import playCourse from '../../../assets/data/play-course.json';
import { MediaPlayerService } from '../media-player/services/media-player.service';
import { GalleryContent } from '../media-player/models/models';
import { LocalStorageService } from 'src/app/core/local-storage.service';

@Component({
  selector: 'edu-show-course',
  templateUrl: './show-course.component.html',
  styleUrls: ['./show-course.component.scss']
})
export class ShowCourseComponent implements OnInit, AfterViewInit {

  showCourse: any = playCourse;
  enrolledCourse: any;
  showRate: boolean = true;
  title: string = "Average, could be better";
  starValue: any;
  star1: string = "";
  star2: string = "";
  star3: string = "checked";
  star4: string = "";
  star5: string = "";
  showCourseContent:any;
  moduleTitle:any;
  moduleDescription:any;
  skillDescription:any;

  constructor(
    private courseDataService: CoursesDataService,
    private route: ActivatedRoute,
    private titleService: Title,
    private elRef: ElementRef,
    private mediaService: MediaPlayerService,
    private router: Router,
    private storageservice: LocalStorageService
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle("Show Course - 21CC Recruitment & Training");
    this.showCourseContent = JSON.parse(this.storageservice.retrieveFromLocalStorage("showCourseContent")); 
    console.log('this.showCourseContent:::::',this.showCourseContent);
    if(this.showCourseContent){
      this.enrolledCourse = this.showCourseContent;
      this.moduleTitle = this.enrolledCourse.modules[0].title;
      this.moduleDescription = this.enrolledCourse.modules[0].description;
      this.skillDescription = this.enrolledCourse.skills;

    }

    const moduleLang = this.route.snapshot.paramMap.get('courseLang');
    const courseSlug = this.route.snapshot.paramMap.get('courseSlug');
    const moduleSlug = this.route.snapshot.paramMap.get('moduleSlug');
    const moduleId = this.route.snapshot.paramMap.get('moduleId');
    
    // if(!moduleId) {
    //   const data = moduleLang + "~" + courseSlug + "~" + moduleSlug;
    //   this.courseDataService.getEnrolledCourseByUrl(data).subscribe((enrolledCourse: any)=>{
    //     this.enrolledCourse = enrolledCourse;
    //     let urlSlug = `${encodeURIComponent(moduleLang)}/${encodeURIComponent(enrolledCourse?.courseSlug)}/${encodeURIComponent(enrolledCourse?.moduleSlug)}`;
    //     this.router.navigate(['/show/course/' + urlSlug]);
    //   });
    // }else {
    //   this.courseDataService.getCourseByCourseId(moduleId).subscribe((enrolledCourse: any)=>{
    //     this.enrolledCourse = enrolledCourse;
    //   });
    // }
  }

  ngAfterViewInit(): void {
    // console.log('moodleId');
    // const moodleId = +this.route.snapshot.paramMap.get('id');
    // console.log(moodleId);
    // for(let i=0; i< this.showCourse.length; i++) {
    //   console.log(this.showCourse[i].moodle_id == moodleId)
    //   if(this.showCourse[i].moodle_id == moodleId) {
    //     let id = this.showCourse[i].module_moodle_id;
    //     this.mediaService.getModuleContent(id)
    //     .subscribe((value: GalleryContent) => {
    //       this.openModule(value.url);
    //     });
    //   };
    // };
    
    this.mediaService.getModuleContent(this.enrolledCourse.modules[0].id)
        .subscribe((value: GalleryContent) => {
          this.openModule(value.url,this.enrolledCourse.modules[0].id);
        });

  }

  openModule(url: string,courseIdNew) {
    // console.log('url::::::',url);
    const iframe = this.elRef.nativeElement.querySelector('iframe');
    iframe.src = url;
    // console.log(iframe.src);
    // const id = +this.route.snapshot.paramMap.get('moduleId');
    const id = +courseIdNew;
    // console.log('id::::::',id);
    const getStatusEvent = (event) => {
      if (event.data === 'finish') {
        setTimeout(() => {
          this.mediaService.getModuleCompletionStatus(id);
        }, 300);
        window.removeEventListener("message", getStatusEvent);
      }
    }
    window.addEventListener("message", getStatusEvent
    , false);
  }

  showModuleDetails(courseId,title,description){
    this.mediaService.getModuleContent(courseId)
        .subscribe((value: GalleryContent) => {
          this.openModule(value.url,courseId);
        });
        this.moduleTitle = title;
        this.moduleDescription = description;
  }

  submitRating(courseId: any) {
    this.courseDataService.rateCourse(courseId).subscribe(()=>{});
  }

  previewRating(value: string) {
    if(value == '1'){
      this.showRate = false;
      switch(this.starValue){
        case 'star1':
          this.title = "Terrible, complete waste of time";
          this.star1 = "checked";
          this.star2, this.star3, this.star4, this.star5 = "";
          break;
        case 'star2':
          this.title = "Poor, pretty disappointed";
          this.star1, this.star2 = "checked";
          this.star3, this.star4, this.star5 = "";
          break;
        case 'star3':
          this.title = "Average, could be better";
          this.star1, this.star2, this.star3 = "checked";
          this.star4, this.star5 = "";
          break;
        case 'star4':
          this.title = "Good, what I expected";
          this.star1, this.star2, this.star3, this.star4 = "checked";
          this.star5 = "";
          break;
        case 'star5':
          this.title = "Superb, could not be better";
          this.star1, this.star2, this.star3, this.star4, this.star5 = "checked";
          break;
        default:
          this.title = "Average, could be better";
          this.star1, this.star2, this.star3 = "checked";
          this.star4, this.star5 = "";
      }
    }else if(value == '2'){
      this.showRate = true;
    }
  }

  mouseEnter(div : string){
    switch(div){
      case 'star1':
        this.title = "Terrible, complete waste of time";
        break;
      case 'star2':
        this.title = "Poor, pretty disappointed";
        break;
      case 'star3':
        this.title = "Average, could be better";
        break;
      case 'star4':
        this.title = "Good, what I expected";
        break;
      case 'star5':
        this.title = "Superb, could not be better";
        break;
    }
  }

  mouseLeave(){
    switch(this.starValue){
      case 'star1':
        this.title = "Terrible, complete waste of time";
        break;
      case 'star2':
        this.title = "Poor, pretty disappointed";
        break;
      case 'star3':
        this.title = "Average, could be better";
        break;
      case 'star4':
        this.title = "Good, what I expected";
        break;
      case 'star5':
        this.title = "Superb, could not be better";
        break;
      default:
        this.title = "Average, could be better";
    }
  }

  starAmount(value: string){
    this.starValue = value;
  }

}
