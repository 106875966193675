import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/core/local-storage.service';
import { CartService } from '../courses/services/cart.service';
import { AdminRegistrationService } from '../admin/registration/services/admin-registration.service';
import { HighlightTitleService } from 'src/app/shared-layout/highlight-title.service';

@Component({
  selector: 'edu-select-plan',
  templateUrl: './select-plan.component.html',
  styleUrls: ['./select-plan.component.scss']
})
export class SelectPlanComponent implements OnInit,OnDestroy  {

  planDetails: any;
  packageName: any;
  loading: boolean = true;
  isAuthDetails:any;
  showUpgrade:boolean = false;

  constructor(
    public localStorage: LocalStorageService,
    private router: Router,
    private cartService: CartService,
    private adminRegistrationService: AdminRegistrationService,
    private highlightTitleService: HighlightTitleService
  ) { }

  ngOnInit(): void {
    this.highlightTitleService.setIsSelectPlanActive(true);
    this.isAuthDetails = this.localStorage.retrieveFromLocalStorage("authDetails");
    if(this.isAuthDetails){
      this.showUpgrade = true;
    }else{
      this.showUpgrade = false;
    }
    this.cartService.setDisplayCartIcon(false);
    this.packageName = this.localStorage.retrieveFromLocalStorage("packageName");
    this.adminRegistrationService.getPlanDetails().subscribe((plans) => {
      // console.log(plans);
      // let filteredPlans = plans.backendResult;
      // this.planDetails = filteredPlans.filter(plan => plan.planLicences >= 49); 
      this.planDetails = plans.backendResult;
      this.loading = false;
    })

    if(this.isAuthDetails){
      console.log('logged in');
    }else{
      this.localStorage.removeFromLocalStorage("username");
      this.localStorage.removeFromLocalStorage("companyName");
      this.localStorage.removeFromLocalStorage("websiteUrl");
      this.localStorage.removeFromLocalStorage("membershipNumber");
      this.localStorage.removeFromLocalStorage("firstName");
      this.localStorage.removeFromLocalStorage("lastName");
    }

  }

  convertCurrencyToIndianFormat(amount){
    return Intl.NumberFormat('en-in', { style: 'currency', currency: 'INR' }).format(amount).split(".")[0];
  }

  navigateToRegistration(plan) {
    // console.log('---------plan---------');
    // console.log(plan);
    this.localStorage.storeInLocalStorage("planId", plan.planId);
    this.localStorage.storeInLocalStorage("razorpayPlanId", plan.razorpayPlanId);
    this.localStorage.storeInLocalStorage("planName", plan.planTitle);
    this.localStorage.storeInLocalStorage("planPrice", plan.planPrice);
    this.localStorage.storeInLocalStorage("planLicenses", plan.planLicences);
    this.localStorage.storeInLocalStorage("planPeriod", plan.planPeriod.name);
    this.localStorage.storeInLocalStorage("planCurrency", plan.currency.name);
    
    // console.log('isAuthDetails', this.isAuthDetails);
    if(this.isAuthDetails){
      this.router.navigate(['/admin-registration/purchase-overview']);
    }else{
      
    this.router.navigate(['/admin-registration/start']);
    }

  }

  ngOnDestroy(): void {
    this.highlightTitleService.setIsSelectPlanActive(false);
  }

}
