<edu-default-layout>
  <div *ngIf="showNotification" class="notification-bar">
    <div class="container-fluid plr-10 pt-1">
      <div class="row align-items-center justify-content-center text-center">
        <div class="col-12 col-md-9 mb-3 align-items-start text-start-lg">
          <div class="d-lg-none">
            <div class="row justify-content-end">
              <div class="col-1 mb-2 text-center mt-2 mr-2">
                <span class="close-btn close-icon close text-start-lg"
                      [inlineSVG]="'assets/images/icons/icon-cross.svg'"
                      (click)="closeNotification()"></span>
              </div>
            </div>
          </div>
          <span class="notification-text text-start">Welcome FFFAI Members! Elevate your freight forwarding operations with our specialised courses. Skill your professionals and stay ahead in the industry.</span>
        </div>
        <div class="col-12 col-md-2 mb-3" style="position:relative; z-index: 4;">
          <a class="register-class btn" target="_blank" rel="noopener noreferrer"
            href="/fffai">Login</a>
        </div>
        <div class="col-12 col-md-1 mb-3 text-center for-mobile text-start-lg d-none d-lg-block">
          <span class="close-btn close-icon close text-start-lg"
                [inlineSVG]="'assets/images/icons/icon-cross.svg'"
                (click)="closeNotification()"></span>
        </div>
      </div>
    </div>
  </div>
  <div class="wrapper p-sm-5">
    <div class="content">
      <!-- <app-carousel [disableButtons]="disableChildButtons" (takeUserToPopUpFormEvent)="takeUserToPopUpForm($event)"></app-carousel> -->
      <app-carousel></app-carousel>
    </div>
    <div class="sections p-1 p-sm-5">
      <div class="text-center title mb-5">Our Approach</div>
      <div class="mb-3 d-flex justify-content-center">
        <img src="../../../assets/images/our-approach/Approach-web.png" alt="" style="width: 100%"
          class="d-none md-2 d-sm-block image" />
        <img src="../../../assets/images/our-approach/Approach-mobile.png" alt="" style="width: 90%"
          class="d-sm-none" />
      </div>
      <br /><br /><br />
      <div class="text-center title mb-5">Courses</div>
      <br />
      <div class="mb-3 mt-1 text-center">
        <img src="../../../assets/images/home/carousel/Courses.png" alt="" style="width: 100%" />
      </div>
      <div class="button-container mt-3">
        <button class="button button1" (click)="showCourseCatalogue()">Download Course Catalogue</button>
        <edu-popup-form *ngIf="showCourseCataloguePopUp"
          (showCourseCataloguePopUpEvent)="closeCourseCataloguePopUp($event)"></edu-popup-form>
        <button class="button button2" routerLink="/library" routerLinkActive="active" #courses="routerLinkActive">
          Explore Courses
        </button>
      </div>
      <br /><br /><br /><br />
      <div class="text-center title mb-5">Our Customers</div>
      <br />
      <div class="px-1 px-sm-3 mb-4">
        <div class="country">Asia</div>
        <div class="logo-container px-sm-1">
          <div class="row justify-content-center">
            <div style="text-align: center;" *ngFor="let logo of indiaLogos">
              <img class="logo-img img-fluid mx-3 mx-sm-3" [src]="logo" alt="Client Logo">
            </div>
          </div>
        </div>
        <div class="country">Europe</div>
        <div class="logo-container px-sm-1">
          <div class="row justify-content-center">
            <div style="text-align: center;" *ngFor="let logo of netherlandsLogos">
              <img class="logo-img img-fluid mx-3 mx-sm-3" [src]="logo" alt="Client Logo">
            </div>
          </div>
        </div>
      </div>
      <br /><br />
      <br /><br />
      <div class="text-center title mb-5">Customer Feedback</div>

      <!-- Small Screen Carousel -->
      <div class="d-block d-md-none">
        <div id="smallScreenCarousel" class="carousel slide" data-ride="carousel">
          <div class="carousel-inner">
            <div class="carousel-item active">
              <!-- First Card -->
              <div class="col-md-12 col-lg-6 mb-4 mb-lg-1">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-3 col-sm-3" style="max-width: 19%;">
                        <img class="svg-icon" src="../../../assets/images/home/right-quotation-sign-svgrepo-com.svg">
                      </div>
                      <div class="col-9 col-sm-9 ml-3">
                        <!-- Person name and designation -->
                        <h5 class="card-title m-0">Renu Bohra</h5>
                        <h6 class="card-subtitle mb-3">Chief People Officer - India & Indian Subcontinent</h6>
                      </div>
                    </div>
                    <!-- Feedback content -->
                    <p class="card-text">The programme by 21CC is helping our facility managers get the new employees
                      up to speed much more quickly. We have decided to let all 2,500 contractual employees of DB
                      Schenker
                      in India do their onboarding with this innovative platform, 21CC Skilled.</p>
                    <img class="img-logo" src="../../../assets/images/home/I1_DB_Schenker.png" alt="Client Logo">
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <!-- Second Card -->
              <div class="col-md-12 col-lg-6 mb-4 mb-lg-1">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-3 col-sm-3" style="max-width: 19%;">
                        <img class="svg-icon" src="../../../assets/images/home/right-quotation-sign-svgrepo-com.svg">
                      </div>
                      <div class="col-9 col-sm-9 ml-3">
                        <!-- Person name and designation -->
                        <h5 class="card-title m-0">Marcel Wouterse</h5>
                        <h6 class="card-subtitle mb-3">Director</h6>
                      </div>
                    </div>
                    <!-- Feedback content -->
                    <p class="card-text">The response from our drivers have been exceptional and the gamification of
                      topics such as fuel efficiency, sustainability etc. has helped improve the level of engagement
                      like we have not seen before.</p>
                    <img class="company-logo" src="../../../assets/images/home/N7_Emons-logo.png" alt="Client Logo">
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <!-- Third Card -->
              <div class="col-md-12 col-lg-6 mb-4 mb-lg-1">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-3 col-sm-3" style="max-width: 19%;">
                        <img class="svg-icon" src="../../../assets/images/home/right-quotation-sign-svgrepo-com.svg">
                      </div>
                      <div class="col-9 col-sm-9 ml-3">
                        <!-- Person name and designation -->
                        <h5 class="card-title m-0">Manon Nijhuis</h5>
                        <h6 class="card-subtitle mb-3">Marketing and Communication Manager</h6>
                      </div>
                    </div>
                    <!-- Feedback content -->
                    <p class="card-text">With the help of gamified e-learning we are able to showcase to the learner –
                      the beauty as well the growth in the logistics sector and we hope to expand this even further in
                      the future!</p>
                    <img class="company-logo" src="../../../assets/images/home/N1_port-of-Twente.png" alt="Client Logo">
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <!-- Fourth Card -->
              <div class="col-md-12 col-lg-6 mb-4 mb-lg-1">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-3 col-sm-3" style="max-width: 19%;">
                        <img class="svg-icon" src="../../../assets/images/home/right-quotation-sign-svgrepo-com.svg">
                      </div>
                      <div class="col-9 col-sm-9 ml-3">
                        <!-- Person name and designation -->
                        <h5 class="card-title m-0">Kathy M. Roberts</h5>
                        <h6 class="card-subtitle mb-3">VP Training and Quality Assurance - North America</h6>
                      </div>
                    </div>
                    <!-- Feedback content -->
                    <p class="card-text">We have seen a marked improvement in productivity by improving their skills and
                      knowledge of the job with effective e-learning.</p>
                    <img class="company-logo" src="../../../assets/images/home/wfslogo.png" alt="Client Logo">
                  </div>
                </div>
              </div>
            </div>
          </div>
            <!-- <ol class="carousel-indicators d-block d-md-none  d-flex justify-content-center">
            <li data-target="#customerFeedbackCarousel" data-slide-to="0" class="active"></li>
            <li data-target="#customerFeedbackCarousel" data-slide-to="1"></li>
            <li data-target="#customerFeedbackCarousel" data-slide-to="2"></li>
            <li data-target="#customerFeedbackCarousel" data-slide-to="3"></li>
          </ol> -->
          <!-- Carousel Controls -->
          <a class="carousel-control-prev" href="#smallScreenCarousel" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a class="carousel-control-next" href="#smallScreenCarousel" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
      </div>
      <!-- Large Screen Carousel -->
      <div class="d-none d-md-block">
        <div id="largeScreenCarousel" class="carousel slide" data-ride="carousel">
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="row">
                <!-- First Card -->
                <div class="col-md-12 col-lg-6 mb-4 mb-lg-1">
                  <div class="card">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-sm-1">
                          <img class="svg-icon" src="../../../assets/images/home/right-quotation-sign-svgrepo-com.svg">
                        </div>
                        <div class="col-sm-10 ml-3">
                          <!-- Person name and designation -->
                          <h5 class="card-title m-0">Renu Bohra</h5>
                          <h6 class="card-subtitle mb-3">Chief People Officer - India & Indian Subcontinent</h6>
                        </div>
                      </div>
                      <!-- Feedback content -->
                      <p class="card-text">The programme by 21CC is helping our facility managers get the new employees
                        up to speed much more quickly. We have decided to let all 2,500 contractual employees of DB
                        Schenker
                        in India do their onboarding with this innovative platform, 21CC Skilled.</p>
                      <img class="img-logo" src="../../../assets/images/home/I1_DB_Schenker.png" alt="Client Logo">
                    </div>
                  </div>
                </div>
                <!-- Second Card -->
                <div class="col-md-12 col-lg-6 mb-4 mb-lg-1">
                  <div class="card">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-sm-1">
                          <img class="svg-icon" src="../../../assets/images/home/right-quotation-sign-svgrepo-com.svg">
                        </div>
                        <div class="col-sm-10 ml-3">
                          <!-- Person name and designation -->
                          <h5 class="card-title m-0">Marcel Wouterse</h5>
                          <h6 class="card-subtitle mb-3">Director</h6>
                        </div>
                      </div>
                      <!-- Feedback content -->
                      <p class="card-text">The response from our drivers have been exceptional and the gamification of
                        topics such as fuel efficiency, sustainability etc. has helped improve the level of engagement
                        like we have not seen before.</p>
                      <img class="company-logo" src="../../../assets/images/home/N7_Emons-logo.png" alt="Client Logo">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="row">
                <!-- Third Card -->
                <div class="col-md-12 col-lg-6 mb-4 mb-lg-1">
                  <div class="card">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-sm-1">
                          <img class="svg-icon" src="../../../assets/images/home/right-quotation-sign-svgrepo-com.svg">
                        </div>
                        <div class="col-sm-10 ml-3">
                          <!-- Person name and designation -->
                          <h5 class="card-title m-0">Manon Nijhuis</h5>
                          <h6 class="card-subtitle mb-3">Marketing and Communication Manager</h6>
                        </div>
                      </div>
                      <!-- Feedback content -->
                      <p class="card-text">With the help of gamified e-learning we are able to showcase to the learner –
                        the beauty as well the growth in the logistics sector and we hope to expand this even further in
                        the future!</p>
                      <img class="company-logo" src="../../../assets/images/home/N1_port-of-Twente.png"
                        alt="Client Logo">
                    </div>
                  </div>
                </div>
                <!-- Fourth Card -->
                <div class="col-md-12 col-lg-6 mb-4 mb-lg-1">
                  <div class="card">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-sm-1">
                          <img class="svg-icon" src="../../../assets/images/home/right-quotation-sign-svgrepo-com.svg">
                        </div>
                        <div class="col-sm-10 ml-3">
                          <!-- Person name and designation -->
                          <h5 class="card-title m-0">Kathy M. Roberts</h5>
                          <h6 class="card-subtitle mb-3">VP Training and Quality Assurance - North America</h6>
                        </div>
                      </div>
                      <!-- Feedback content -->
                      <p class="card-text">We have seen a marked improvement in productivity by improving their skills
                        and knowledge of the job with effective e-learning.</p>
                      <img class="company-logo" src="../../../assets/images/home/wfslogo.png" alt="Client Logo">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <a class="carousel-control-prev" href="#largeScreenCarousel" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a class="carousel-control-next" href="#largeScreenCarousel" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
      </div>

      <!-- get in touch form -->
      <section id="get-in-touch-container">
        <!-- *ngIf="!isFormSubmitted && !sharedService.hideGetInTouchFormAtBottomOfHomePage" -->
        <edu-get-in-touch-form  [popUpFormEvent]="popUpFormEvent"
          (isFormSubmittedEvent)="isFormSubmittedEventReceived($event)"
          *ngIf="!isFormSubmitted"
          ></edu-get-in-touch-form>
      </section>

      <!-- <div id="customerFeedbackCarousel" class="carousel slide" data-ride="carousel">
      <div class="carousel-inner">
        <div class="carousel-item active">
            <div class="col-md-12 col-lg-6 mb-4 mb-lg-1">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-1">
                      <img class="svg-icon" src="../../../assets/images/home/right-quotation-sign-svgrepo-com.svg">
                    </div>
                    <div class="col-sm-10 ml-3">
                      <h5 class="card-title m-0">Renu Bohra</h5>
                      <h6 class="card-subtitle mb-3">Marketing Communication</h6>
                    </div>
                  </div>
                  <p class="card-text">This program is helping our facility managers to get the new employees up to speed much more quickly then would otherwise have been the case.We have also decided to let all 2,500 contractual employees of DB Schenker in India use the 21CC App and will do their on-boarding via this innovative platform.</p>
                  <img class="img-logo" src="../../../assets/images/home/I1_DB_Schenker.png" alt="Client Logo">
                </div>
              </div>
            </div>
        </div>
        <div class="carousel-item">
            <div class="col-md-12 col-lg-6 mb-4 mb-lg-1">
              <div class="card">
                <div class="card-body">
                <div class="row">
                  <div class="col-sm-1">
                    <img class="svg-icon" src="../../../assets/images/home/right-quotation-sign-svgrepo-com.svg">
                  </div>
                  <div class="col-sm-10 ml-3">
                    <h5 class="card-title m-0">Marcel Wouterse</h5>
                    <h6 class="card-subtitle mb-3">Director | EMONS CARGO 2WIN</h6>
                  </div>
                </div>
                <p class="card-text">The response from our drivers have been exceptional and the gamification of topics such as fuel efficiency, sustainability etc. has helped improve the level of engagement like we have not seen before.</p>
                <img class="company-logo" src="../../../assets/images/home/N7_Emons-logo.png" alt="Client Logo">
              </div>
            </div>
            </div>
        </div>
        
        <div class="carousel-item">
            <div class="col-md-12 col-lg-6 mb-4 mb-lg-1">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-1">
                      <img class="svg-icon" src="../../../assets/images/home/right-quotation-sign-svgrepo-com.svg">
                    </div>
                    <div class="col-sm-10 ml-3">
                      <h5 class="card-title m-0">Manon Nijhuis</h5>
                      <h6 class="card-subtitle mb-3">Marketing Communication</h6>
                    </div>
                  </div>
                  <p class="card-text">With the help of gamified e-learning we are able to showcase to the learner – the beauty as well the growth in the logistics sector and we hope to expand this even further in the future!</p>
                  <img class="company-logo" src="../../../assets/images/home/N1_port-of-Twente.png" alt="Client Logo">
                </div>
              </div>
            </div>
            </div>

            <div class="carousel-item">            
            <div class="col-md-12 col-lg-6 mb-4 mb-lg-1">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-1">
                      <img class="svg-icon" src="../../../assets/images/home/right-quotation-sign-svgrepo-com.svg">
                    </div>
                    <div class="col-sm-10 ml-3">
                      <h5 class="card-title m-0">Kathy M. Roberts</h5>
                      <h6 class="card-subtitle mb-3">VP Training and Quality Assurance - North America</h6>
                    </div>
                  </div>
                  <p class="card-text">We have seen a marked improvement in productivity by improving their skills and knowledge of the job with effective e-learning.</p>
                  <img class="company-logo" src="../../../assets/images/home/wfslogo.png" alt="Client Logo">
                </div>
              </div>
            </div>
        </div>
      </div>
      <ol class="carousel-indicators d-block d-md-none  d-flex justify-content-center">
        <li data-target="#customerFeedbackCarousel" data-slide-to="0" class="active"></li>
        <li data-target="#customerFeedbackCarousel" data-slide-to="1"></li>
        <li data-target="#customerFeedbackCarousel" data-slide-to="2"></li>
        <li data-target="#customerFeedbackCarousel" data-slide-to="3"></li>
      </ol>

      <a class="carousel-control-prev" href="#customerFeedbackCarousel" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      
      <a class="carousel-control-next" href="#customerFeedbackCarousel" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>  -->
    </div>
  </div>
</edu-default-layout>

<!-- Old Home Page 
  <div class="giz-center">
    <div class="giz-container-box">
      <div class="giz-text">21CC Education has partnered with GIZ through the VET Toolbox
        <br />
        initiative to skill delivery riders in Nigeria.
      </div>
      <button class="giz-register-btn" (click)="redirectToGizRegistrationForm()">REGISTER NOW</button>
    </div>
  </div>
  <div class="download-app app-desktop mobile-skilling">
    <div class="container">
      <h3><b>21CC Skilled can: </b></h3>
      <ul style="list-style-type: none;">
        <b>
          <li><i class="fa-solid fa-circle" style="font-size:12px; margin-right: 7px;"></i>Allow easy access to skilled
            workers</li>
          <li><i class="fa-solid fa-circle" style="font-size:12px; margin-right: 7px;"></i>
            Enable organisations to onboard, train, upskill and assess their
            workforce to maximise learning retention rates and on the job
            performance
          </li>
          <li><i class="fa-solid fa-circle" style="font-size:12px; margin-right: 7px;"></i>Help blue-collar workers
            build sustainable livelihoods</li>
        </b>
      </ul>
      <div class="app-link">
        <div class="row col">
          <div class="col-2 align-items-center">
            <p>
              <img src="assets/images/home/qrcode.svg" width="92px" alt="qrcode" />
            </p>
          </div>
          <div class="col-7 justify-content-start">
            <h4><b>Scan the QR code to download</b></h4>
            <div class="row justify-content-start mt-4 mx-5">
              <div class="col-8 mx-4">
                <a href="https://apps.apple.com/us/app/the-21cc-app/id1512910768" target="_blank"><img
                    src="assets/images/home/app_store.png" width="120px" alt="apple_play_store_image" /></a>
                <a href="https://play.google.com/store/apps/details?id=com.education21cc.logistics&hl=en&gl=US"
                  target="_blank">
                  <img src="assets/images/home/google_play_store.png" width="120px" alt="google_play_store" /></a>
              </div>
            </div>
          </div>
          <div class="col-3 div-image">
            <img src="assets/images/home/Mobile-min.png" width="259px;" alt="Mobile-image" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="app-mobile">
    <div class="col full-width">
      <div class="row">
        <h3>21CC Skilled can:</h3>
        <ul>
          <h5>
            <b>
              <li><i class="fa-solid fa-circle" style="font-size:6px; margin-right: 5px;"></i>
                <p>Allow easy access to skilled workers</p>
              </li>
              <li><i class="fa-solid fa-circle" style="font-size:6px; margin-right: 5px;"></i>
                <p>
                  Enable organisations to onboard, train, upskill and assess their
                  workforce to maximise learning retention rates and on the job
                  performance
                </p>
              </li>
              <li><i class="fa-solid fa-circle" style="font-size:6px; margin-right: 5px;"></i>
                <p>Help blue-collar workers build sustainable livelihoods</p>
              </li>
            </b>
          </h5>
        </ul>
      </div>
      <br />
      <div class="row justify-content-center">
        <div class="vertical-app-link">
          <div class="row justify-content-center padding-ten-percent">
            <p>Download <br />21CC Skilled</p>
            <p>
              <a *ngIf="link1" target="_blank" href="link1"><button class="btn btn-primary">Get 21CC
                  Skilled</button></a>
              <a *ngIf="link2" target="_blank" href="link2"><button class="btn btn-primary">Get 21CC
                  Skilled</button></a>
            </p>
          </div>
          <div class="row justify-content-center">
            <img src="assets/images/home/Mobile-min.png" width="259px;" alt="mobile-min" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered d-flex justify-content-center" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <div class="embed-responsive embed-responsive-16by9">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/tg92djRMBfE"
              title="YouTube video player" frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="center-content why21cc">
    <div class="container">
      <h1><b>Why 21CC Education?</b></h1>
      <br />
      <div class="content-center">
        <div class="flex-container">
          <div class="div" *ngFor="let whyData of whysData">
            <div class="center-content">
              <app-why-card [whyData]="whyData"></app-why-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="center-content why21cc">
    <div class="container">
      <h3><b>Products</b></h3>
      <br />
      <div class="content-center">
        <div class="flex-container">
          <div class="div" *ngFor="let productData of productsData">
            <div class="center-content">
              <app-product-card [productData]="productData"></app-product-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br /><br />
  <app-know-more></app-know-more>
 -->