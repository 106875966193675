<edu-default-layout>
    <edu-spinner *ngIf="loading"></edu-spinner>
    <div class="productrow d-flex position-relative pt-4">
        <div class="">
            <div class="image" style="background-image: url()"></div>
        </div>
        <div class="title pl-3">
            displayName
        </div>
        <div class="price">
            pricecurrent
        </div>
    </div>
    <div class="container fill-height checkout pt-md-5" *ngIf="!emptyCart">
        <h2 class="text-left row col pt-5">Cart</h2>
        <div class="row">
            <div class="col-md-9 cart container pt-3 order-2 order-md-1">
                <div class="row">
                    <div class="col-md-11 cart-price">
                        Price
                    </div>
                </div>
                <div clas="row">
                    <div class="col-md-11">
                        <!-- <div class="productrow checkbox d-flex position-relative">
                        <div class="row">
                            <input type="checkbox" [checked]="check" (change)="checkall()">
                            <h2 class="select title pl-3">Check All</h2>
                        </div>
                        <div>
                            <button class="btn border trash" (click)="removeFromCartLocalStorage(item)"></button>
                        </div>
                    </div> -->
                        <div class="productrow d-flex position-relative" *ngFor="let item of arrOfItems">
                            <!-- <div class="d-flex align-items-center" style="margin-right: 5px;position: relative;">
                                <input class="d-flex align-items-center" #saveUserNameCheckBox type="checkbox" (change)="totalCart()" [(ngModel)]="item.check" />
                            </div>   -->
                            <div class="d-flex align-items-center">
                                <img class="image" [src]="item?.imageUrl" />
                            </div>
                            <div class="title pl-3 pt-4">
                                {{ item?.name }}
                            </div>
                            <div class="pt-4" [ngSwitch]="model.option">
                                <div *ngSwitchCase="'Dollar'">${{ item?.dollarPrice?.toFixed(2) }}</div>
                                <div *ngSwitchCase="'Euro'">€{{ item?.euroPrice?.toFixed(2) }}</div>
                                <div *ngSwitchCase="'Gbp'">£{{ item?.gbpPrice?.toFixed(2) }}</div>
                                <div *ngSwitchDefault>₹{{ item?.cost }}</div>
                                <div>
                                    <button class="btn border trash" (click)="removeFromCartLocalStorage(item)"></button>
                                </div>
                            </div>
                            <!-- <div class="pt-4">
                                <div>{{ currency }} {{ item?.price }}</div>
                                <div>
                                    <button class="btn border trash" (click)="removeFromCartLocalStorage(item)"></button>
                                </div>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-md-1"></div>
                </div>
            </div>
            <div class="col-md-3 summary order-1 order-md-2 mt-2">
                <div class="card p-3 px-4">
                    <section class="text-muted pb-2" *ngIf="discountData > 0">
                        <span class="mt-2" class="price-discount">Discount {{ discountData }}% off</span>
                        <div class="mt-2" [ngSwitch]="model.option">
                            <span class="price-currency" *ngSwitchCase="'Dollar'">-${{ discountDollar?.toFixed(2) }}</span>
                            <span class="price-currency" *ngSwitchCase="'Euro'">-€{{ discountEuro?.toFixed(2) }}</span>
                            <span class="price-currency" *ngSwitchCase="'Gbp'">-£{{ discountGbp?.toFixed(2) }}</span>
                            <span class="price-currency" *ngSwitchDefault>-₹{{ discountPrice?.toFixed(2) }}</span>
                        </div>
                        <br />
                        <hr class="hr-line">
                    </section>
                    <label *ngFor="let radiobutton of radioItems">
                        <input style="margin-top: 10px" type="radio" name="options"
                            (click)="model.option = radiobutton; onRadioButtonClick()"
                            [checked]="radiobutton === model.option"> {{radiobutton}}
                    </label>
                    <!-- <select style="margin-top: 10px" name="options" #selectElement (change)="onSelectChange(selectElement.value)">
                        <option *ngFor="let country of countries" [value]="country">{{ country }}</option>
                      </select>                                            -->
                    <br /><br />
                    <section class="text-muted pb-2">
                        Total
                    </section>
                    <del *ngIf="!(dollar >= totalDollar)">
                        <h5 class="price-total text-nowrap" [ngSwitch]="model.option">
                            <div *ngSwitchCase="'Dollar'">${{ totalDollar.toFixed(2) }}</div>
                            <div *ngSwitchCase="'Euro'">€{{ totalEuro.toFixed(2) }}</div>
                            <div *ngSwitchCase="'Gbp'">£{{ totalGbp.toFixed(2) }}</div>
                            <div *ngSwitchDefault>₹{{ totalPrice.toFixed(2) }}</div>
                        </h5>
                    </del>
                    <h5 class="price-total text-nowrap" [ngSwitch]="model.option">
                        <div *ngSwitchCase="'Dollar'">${{ dollar.toFixed(2) }}</div>
                        <div *ngSwitchCase="'Euro'">€{{ euro.toFixed(2) }}</div>
                        <div *ngSwitchCase="'Gbp'">£{{ gbp.toFixed(2) }}</div>
                        <div *ngSwitchDefault>₹{{ price }}</div>
                    </h5>
                    <!-- <h5 class="price-total text-nowrap">
                        <div>{{ currency }}{{ totalAmount }}</div>
                    </h5> -->
                    <form action="purchase-complete.php" class="mt-3" method="POST">
                        <input type="submit" value="Checkout" (click)="pay()" class="razorpay-payment-button">
                    </form>
                </div>
            </div>
        </div>
    </div>

    <div class="container fill-height checkout pt-md-5 d-flex align-items-center" *ngIf="emptyCart">
        <div class="m-auto">
            <h2 class="pt-5 justify-content-center my-md-4">Cart</h2>
            <div class="text-center">
                <p>
                    <img src="assets/images/checkout/images/larger-box.svg">
                </p>
                <p class="my-3">
                    Your cart is empty. Find courses and continue shopping
                </p>
                <p>
                    <a routerLink="/library">
                        <button class="btn btn-primary mt-4">Find Courses</button>
                    </a>
                </p>
            </div>
        </div>
    </div>

    <br /><br /><br /><br />
</edu-default-layout>