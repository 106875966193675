import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
// import { GktAuthService } from 'gkt-auth-library';
import { AngularAuthService } from 'angular-auth-library';

import { Observable } from 'rxjs';
import { LanguageService } from 'src/app/core/language/language.service';
import { LocalStorageService } from 'src/app/core/local-storage.service';
import { LocaleHelpersService } from 'src/app/core/locale-helpers.service';
import { Role, UserService } from 'src/app/core/user.service';
import { CartService } from 'src/app/modules/courses/services/cart.service';
import { CoursesDataService } from 'src/app/modules/courses/services/courses-data.service';
import { UserProfile } from 'src/app/modules/user-profile/models/models';
import { UserProfileRestService } from 'src/app/modules/user-profile/services/user-profile-rest.service';
import { UserProfileService } from 'src/app/modules/user-profile/services/user-profile.service';
import { HighlightTitleService } from '../highlight-title.service';
import { companyDetails, companyUserDetails, purchaseHistory } from "src/app/modules/user-profile/models/models";
import { FffaiService } from 'src/app/modules/fffai/services/fffai.service';
import { NavigationService } from 'src/app/core/navigation.service';
import countriesList from 'src/assets/data/countries.json';
import { RegistrationService } from 'src/app/modules/registration/services/registration.service';

@Component({
  selector: 'edu-top-nav-bar',
  templateUrl: './top-nav-bar.component.html',
  styleUrls: ['./top-nav-bar.component.scss']
})

export class TopNavBarComponent implements OnInit {

  userProfile$: Observable<UserProfile>;
  avatarImageSrc$: Observable<SafeResourceUrl>;
  countryName: string;

  isLoggedIn: boolean | undefined;
  userInfo: any;
  userIsUnverified: boolean;
  role$: Observable<Role[]>;

  smallHeader: boolean;
  addContainer: boolean;
  employeeNumber: number;
  companyLogo: string;
  heightValue: number = 122;
  about = {
    isActive: false
  };
  project = {
    isActive: false
  };
  totalItems: number = 0;
  @Input() avatarLoaded: boolean;
  showFantasyMap: boolean;

  fffaiRole: any;
  fffai:any;
  isCoursesForTeams: boolean = true;
  checkCoursesForTeams:any;
  displayCartIcon = true;
  isSelectPlanActive: boolean = false;
  companyDetails$: Observable<companyDetails>;
  domainOwnerName:any;
  userRole:any;
  isFFFAIWAI: boolean = false;

  constructor(
    private userProfileRestService: UserProfileRestService,
    private userProfileService: UserProfileService,
    private localeHelpers: LocaleHelpersService,
    private courseDataService: CoursesDataService,
    private storageService: LocalStorageService,
    private userService: UserService,
    private languageService: LanguageService,
    private authService: AngularAuthService,
    private router: Router,
    private renderer: Renderer2,
    private cartService: CartService,
    private highlightTitleService: HighlightTitleService,
    private fffaiService: FffaiService,
    private navigationService: NavigationService,
    private registrationService: RegistrationService, 
  ) { }

  ngOnInit(): void {
    // this.checkCoursesForTeams =  this.storageService.retrieveFromLocalStorage("isCoursesFromTeams");
    this.userRole = this.storageService.retrieveFromLocalStorage("userRole");
    this.highlightTitleService.isSelectPlanActive$.subscribe(isActive => {
      this.isSelectPlanActive = isActive;
    });

    this.cartService.displayCartIcon$.subscribe((display) => {
      this.displayCartIcon = display;
    });

    this.userProfile$ = this.userProfileService.userProfile$;
    this.avatarImageSrc$ = this.userProfileService.getAvatarImageSrc();
    this.userProfile$.subscribe(profile => this.countryName = this.nameFromCountryCode(profile.user.country));
    // const contactLink = document.getElementById('contact');
    // contactLink.addEventListener('click', this.scrollToTop);
        this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && event.url === '/') {
        this.scrollToTop();
      }
    });

    this.companyDetails$ = this.fffaiService.companyDetails$;
    this.companyDetails$.subscribe((companyDetails)=>{
      // console.log(companyDetails);
      this.domainOwnerName = companyDetails.domainOwner;
      if(this.domainOwnerName){
        console.log(this.domainOwnerName);
        if(this.domainOwnerName == 'FFFAI Domain Owner' || this.domainOwnerName == 'WAI Domain Owner'){
          this.isFFFAIWAI = true;
        }else{
          this.isFFFAIWAI = false;
        }
      }
    })

    if (localStorage.getItem('fffaiRole')) {
       localStorage.removeItem('fffaiRole')
      this.isLoggedIn = false;
    }else{
      if (localStorage.getItem('authDetails')) {
        this.isLoggedIn = true;
        this.userProfileRestService.getUserProfile().subscribe(data => {
          this.userInfo = data;
        });
      } else {
        this.isLoggedIn = false;
      }
    }

   

    if (!(this.employeeNumber == undefined && this.companyLogo == undefined)) {
      if (this.employeeNumber > 0 && this.companyLogo != '') {
        this.heightValue = 255;
      } else if (this.employeeNumber > 0 && this.companyLogo == '') {
        this.heightValue = 135;
      } else if (!(this.employeeNumber > 0) && this.companyLogo != '') {
        this.heightValue = 230;
      }
    }

  
    if (localStorage.getItem('shopping-cart')) {
      var item = localStorage.getItem('shopping-cart').slice(0, localStorage.getItem('shopping-cart').length - 1);
      var arrOfItemsId = item.split(',');
      this.totalItems = arrOfItemsId.length;
    }else{
      this.totalItems=null;
    }

    this.courseDataService.componentMethodCalled$.subscribe(() => {
      this.ngOnInit();
    });

    const authDetails = JSON.parse(this.storageService.retrieveFromLocalStorage('authDetails'));
    if (authDetails) {
      this.userIsUnverified = authDetails?.username ? false : true;
    }
    this.role$ = this.userService.role$;
  }

  checkdisplaycart(){
    if(this.checkCoursesForTeams == 'yes'){
      this.isCoursesForTeams= false;
    }else{
      this.isCoursesForTeams= true;
    }
  }

  checkRole() {
    const authDetails = JSON.parse(this.storageService.retrieveFromLocalStorage('authDetails'));
    if (authDetails) {
      this.showFantasyMap = (authDetails?.roles[0] == "ROLE_STUDENT") ? true : false;
    }
  }

  // nameFromCountryCode(countryCode) {
  //   return this.localeHelpers.nameFromCountryCode(countryCode);
  // }

  setLanguage(lang: string) {
    this.languageService.setActiveLang(lang);
  }

  scrollToTop() {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }
  
  logout2(){
    console.log('calling second logout')
    this.storageService.removeFromLocalStorage('fffaiWai');
    localStorage.removeItem('authDetails');
    localStorage.removeItem('username');
    localStorage.removeItem('fffaiRole');
    localStorage.removeItem('adminSSOData');
    localStorage.removeItem('companyId');
    
    if(this.domainOwnerName == 'FFFAI Domain Owner'){
      this.fffaiService.logout();
     
      this.router.navigate(['/fffai/login']);
    }else{
      // this.router.navigate(['/registration/start-with-login']);
      this.router.navigate(['/home']);
    }
    // window.location.reload();
    this.storageService.removeFromLocalStorage('domainOwnerName');
    this.navigationService.removeCurrentPageFromBackHistory();
    
  }
  logout() {
    console.log('calling first logout')
    this.authService.logout();
    this.storageService.storeInLocalStorage('isLoggedOut', "true");
    this.storageService.removeFromLocalStorage('authDetails');
    this.storageService.removeFromLocalStorage('username');
    
    window.removeEventListener('hashchange', this.scrollToTop);
    if(this.domainOwnerName == 'FFFAI Domain Owner'){
      this.router.navigate(['/fffai/login']);
    }else{
      window.location.reload();
      // this.router.navigate(['/registration/start-with-login']);
      this.router.navigate(['/home']);
    }
    this.storageService.removeFromLocalStorage('domainOwnerName');
    this.navigationService.removeCurrentPageFromBackHistory();

  }

  nameFromCountryCode(countryCode: any) {
    // Assuming you have the countriesList imported here
    const country = countriesList.find(c => c.countryCode === countryCode);
    return country ? country.countryName : 'Unknown';
  }

  navigateToLogin(){
    this.registrationService.setRegistrationMode('login');
    this.router.navigate(['/registration/start-with-login']);
  }
  navigateToSignup(){
    this.registrationService.setRegistrationMode('registration');
    this.router.navigate(['/registration/start-with-signup']);
  }

}
