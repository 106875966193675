import { Component, Input, AfterViewChecked, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import careerData from '../../../assets/data/career.json';

@Component({
  selector: 'app-career-card',
  templateUrl: './career-card.component.html',
  styleUrls: ['./career-card.component.scss']
})
export class CareerCardComponent implements OnInit, OnChanges, AfterViewChecked {

  @Input() tabName: any;
  careerJson: any = careerData;
  careers: any[];

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewChecked() {
    const cards = document.querySelectorAll('.career-article .card');

    let maxHeight = 0;
    cards.forEach(function(card: HTMLElement) {
      const cardHeight = card.offsetHeight;
      if (cardHeight > maxHeight) {
        maxHeight = cardHeight;
      }
    });

    cards.forEach(function(card: HTMLElement) {
      card.style.minHeight = maxHeight + 'px';
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    switch(this.tabName){
      case "All":
        this.careers = this.careerJson;
        break
      case "Creative":
        this.careers = this.careerJson.filter((x: any) => 
          x.category == "Creative"
        );
        break
      case "Web Developer":
        this.careers = this.careerJson.filter((x: any) => 
          x.category == "Web Developer"
        );
        break
      default:
        this.careers = this.careerJson;
    }
  }

}
