<div class="container career-index">
    <div class="row overflow-hidden" id="card-container">
        <div class="career-article col-12 col-sm-6 col-md-4" *ngFor="let career of careers"
            data-category="categorySlug">
            <div class="card flex-shrink-0 mt-3">
                <a routerLink="/career-details/{{ career.title }}">
                    <img class="card-img-top" [src]="career.image" [alt]="career.title" />
                </a>
                <section class="author-and-category d-flex justify-content-between px-3 mt-3">
                    <div class="author">
                        {{ career.author }}
                    </div>
                    <div class="category text-muted">
                        {{ career.category }}
                    </div>
                </section>
                <section class="title px-3 py-2">
                    <a routerLink="/career-details/{{ career.title }}">
                        <h6>{{ career.title }}</h6>
                    </a>
                </section>
                <section class="px-3 py-2 text-muted mt-3 overflow-hidden">
                    {{ career.highlight }}
                </section>
                <section class="p-3 text-muted">
                    <i class="fa fa-clock"></i>
                    {{ career.date }}
                </section>
            </div>
        </div>
    </div>
</div>