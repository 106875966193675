import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild,AfterViewInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import {HomeComponent} from '../../modules/home/home.component';
import { SharedService } from 'src/app/services/shared.service';
import { AuthRoleDetails } from 'src/app/core/user.service';
import { LocalStorageService } from 'src/app/core/local-storage.service';
declare var $: any; 

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit{
  @Output() navigateToSection = new EventEmitter<void>();
  isMobileResolution: boolean;
  @Output() takeUserToPopUpFormEvent = new EventEmitter<string>();
  // @ViewChild('mycarousel') mycarousel: ElementRef;
  @Input() disableButtons: boolean;
  isAndroid: boolean;
  isIOS: boolean;


  constructor(private router: Router, private home: HomeComponent, private sharedService: SharedService, private storageService: LocalStorageService) {
    this.isAndroid = /android/i.test(navigator.userAgent);
    this.isIOS = /iphone|ipad|ipod/i.test(navigator.userAgent);
  }

  ngOnInit(): void {

    // if (this.mycarousel) {
    //   $(this.mycarousel.nativeElement).carousel('cycle');
    // }

    if (window.innerWidth < 768) {
      this.isMobileResolution = true;
    } else {
      this.isMobileResolution = false;
    }
  }

  getAppStoreUrl(): string {
    if (this.isAndroid) {
      return 'https://play.google.com/store/apps/details?id=com.education21cc.logistics&hl=en&gl=US&pli=1';
    } else if (this.isIOS) {
      return 'https://apps.apple.com/us/app/the-21cc-app/id1512910768';
    } else {
      const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      if (isSafari) {
        return 'https://apps.apple.com/us/app/the-21cc-app/id1512910768';
      } else {
        return 'https://play.google.com/store/apps/details?id=com.education21cc.logistics&hl=en&gl=US&pli=1';
      }
    }
  }
  
  // ngAfterViewInit() {
  //   // Resume the carousel when the component becomes visible again
  //   if (this.mycarousel) {
  //     $(this.mycarousel.nativeElement).carousel('cycle');
  //   }
  // }

  takeUserToPopUpForm() {
    if (this.sharedService.disableButtons) {
      console.log('scroll disabled');
    } else {
      console.log('scroll enabled');
      this.home.scrollToSection();
      this.navigateToSection.emit();
    }
  }

  takeUserToPopUpFormold() {
    
    // if(this.disableButtons){
    //   console.log('scroll diabled');
    // }else{
    //   console.log('scroll enabled');
    //   this.home.scrollToSection();
    // }

    if(this.sharedService.disableButtons){
      console.log('scroll diabled');
    }else{
      console.log('scroll enabled');
      this.home.scrollToSection();
    }
    
    
    // this.router.navigate([`/home#get-in-touch-container`]);
    // this.takeUserToPopUpFormEvent.emit('get-in-touch-container');
  }

  redirectUserToPage() {
    const authDetails = this.retrieveAuthDetails();
    if(!authDetails) {
      this.router.navigate(['/select-plan']);
    } else {
      this.router.navigate(['/admin-registration/manage-learners']);
    }
  }

  retrieveAuthDetails(): AuthRoleDetails {
    const authDetails: string = this.storageService.retrieveFromLocalStorage('authDetails');
    return JSON.parse(authDetails);
  }

}
