<edu-default-layout>
    <edu-spinner *ngIf="loading"></edu-spinner>
    <header>
        <div class="container-fluid body-course-details">
            <div class="row col-12 col-sm-8 flex-column">
                <h2 class="text-left ml-0 my-1">
                    {{ bundle?.name }}
                </h2>
                <ul class="list-unstyled d-flex list-group-horizontal text-white mb-4">
                    <li class="unstyled-list-item">
                        <p style="color: white;"><strong>Language</strong> - {{ getFormattedLanguage(bundle?.language) }}</p>
                    </li>
                </ul>
                <div *ngIf="bundle?.ratings?.length" class="w-100">
                    <section class="rating">
                        <span class="total">{{ bundle?.averageRatingScore?.toFixed(1) }}</span>&nbsp;
                        <!-- <span [ngClass]="bundle?.averageRatingScore?.toFixed(1) >= '1' ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                        <span [ngClass]="bundle?.averageRatingScore?.toFixed(1) >= '2' ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                        <span [ngClass]="bundle?.averageRatingScore?.toFixed(1) >= '3' ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                        <span [ngClass]="bundle?.averageRatingScore?.toFixed(1) >= '4' ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                        <span [ngClass]="bundle?.averageRatingScore?.toFixed(1) >= '5' ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>&nbsp; -->
                        <div class="reviews" style="width: fit-content; display: inline;">
                            <div class="reviews-overview" style="width: fit-content; display: inline;">
                                <div class="rating-stars d-inline-block position-relative mr-2">
                                    <fieldset class="rating" style="margin-bottom: 10px;">
                                        <div class="star-ratings">
                                            <div class="fill-ratings" [style.width.%]="finalAvgPercentage">
                                                <span>★★★★★</span>
                                            </div>
                                            <div class="empty-ratings">
                                                <span>★★★★★</span>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                        <span class="text-light">({{ bundle?.ratings?.length }} ratings)</span>
                    </section>
                </div>
            </div>
        </div>
    </header>

    <div *ngIf="isCoursesFromTeams == 'no'" class="mobile-sticky d-sm-none border m-0 border-primary w-100 bg-white p-2 row">
        <div class="col-6 price">
            <!-- <h4 class="m-0 current">{{ bundle?.currency }}{{ bundle?.cost }}</h4> -->
            <h4 class="m-0 current">₹{{ bundle?.cost }}</h4>
            <!-- <s class="text-muted pt-1 pl-2">₹{{ bundle?.previousPrice }}</s> -->
        </div>
        <form class="p-0 col-6">
            <button type="submit" class="btn btn-primary w-100" (click)="createRzpayOrder(bundle)">Buy
                now</button>
        </form>
    </div>
    <div *ngIf="isCoursesFromTeams == 'yes'" class="mobile-sticky d-sm-none border m-0 border-primary w-100 bg-white p-2 row">
        <form class="p-0 col-12">
            <button type="submit" class="btn btn-primary w-100" (click)="navigateToPlan()">Purchase for your team</button>
        </form>
    </div>
    <div class="container d-sm-none">
        <section class="buy-buttons my-4">
        </section>
        <section class="features mt-4">
            <h5>Course features</h5>
            <ul class="text-muted list-unstyled">
                <!-- <li>
                    <p class="features text-muted">Category -</p> <a
                        routerLink="/course-category-details/{{ bundle?.group?.id }}">{{ bundle?.group?.name }}</a>
                </li> -->
                <!-- <li>
                    <p class="features-value">{{ bundle?.courses?.length }} modules</p>
                </li> -->
                <li>
                    <p class="features text-muted">Duration -</p>&nbsp;
                    <p class="features-value"><span *ngIf="rHours">{{ rHours }}h </span><span *ngIf="rMinutes">{{ rMinutes }}m</span></p>                </li>
                <li>
                    <p class="features text-muted">Course validity -</p>&nbsp;
                    <p class="features-value">6 months</p>
                </li>
                <li>
                    <p class="features text-muted">Access -</p>&nbsp;
                    <p class="features-value">All web platforms</p>
                </li>
                <li>
                    <p class="features text-muted">Certificate of completion -</p>&nbsp;
                    <p class="features-value">Sharable</p>
                </li>
            </ul>
        </section>
        <br />
        <section class="career-possibilities" *ngIf="bundle?.careerPossibilities?.length > 0">
            <h5>Career possibilities</h5>
            <ul class="text-muted list-unstyled">
                <div *ngFor="let careerPossibility of bundle.careerPossibilities">
                    <li [innerHTML]="careerPossibility"></li>
                </div>
            </ul>
        </section>
    </div>
    <div class="container-fluid body-course-details">
        <div class="row">
            <div class="col-12 col-sm-9">
                <h2 class="text-left mb-2 mt-4 sub-Heading" *ngIf="bundle?.description?.length > 0">About this course </h2>
                <section style="line-height: 1.5;">
                    <!-- <p class="text-desc" [innerHTML]="bundle.description"></p> -->
                    <p class="text-desc" [innerHTML]="bundle.description">
                    Warehouses occupy a key position in global supply chains, but the warehousing landscape has undergone tremendous changes 
                    in the past couple of decades with emerging market trends— such as e-commerce and increasingly demanding customer expectations. 
                    Warehouses no longer function as mere storage facilities. As the role of warehouses has expanded, so has the role of warehouse managers.</p>
                </section>

                <!-- <h2 class="text-left mb-2 mt-4 sub-Heading" *ngIf="bundle?.whatYouWillLearn?.length > 0">What you will learn </h2>
                <ul class="list-unstyled text-muted topics">
                    <div *ngFor="let topic of bundle?.whatYouWillLearn">
                       <img src="assets/images/green-tick.svg" /><li [innerHTML]="topic" style="font-size: medium;"></li>
                    </div>
                </ul> -->
                <h2 class="text-left mb-2 mt-5 sub-Heading" *ngIf="bundle?.whatYouWillLearn?.length > 0">What you will learn </h2>
                <ul class="list-unstyled text-muted topics">
                    <div *ngFor="let wyl of bundle?.whatYouWillLearn">
                      <li style="font-size: medium;">
                        {{wyl}}</li>
                    </div>
                </ul>
                <h3 class="text-left mb-2 mt-4 sub-Heading" *ngIf="bundle?.skills?.length > 0">Skills you will gain</h3>
                <ul class="list-inline list-unstyled skills">
                    <li class="rounded-pill border border-primary mt-1 px-4 py-2 list-inline-item" [innerHTML]="skill"
                        style="font-size: medium;" *ngFor="let skill of bundle?.skills"></li>
                </ul>
                <div >
            
                    <h2 *ngIf="bundle?.modules?.length > 0" class="text-left mb-2 d-block d-md-flex justify-content-between mb-4 mt-5">
                        <div class="sub-Heading">Course modules</div>
                        <div class="small text-muted"><span *ngIf="rHours">{{ rHours }}h </span><span *ngIf="rMinutes">{{ rMinutes }}m</span></div>
                    </h2>
                    <div *ngIf="bundle?.modules?.length > 0" class="accordion cc21-accordion" id="coursesContained" role="tablist" aria-multiselectable="true">
                        <div class="accordion-item my-2" *ngFor="let course of bundleCourses">
                            <div class="accordion-header py-2 pb-3" role="tab" id="header-141">
                                <a class="d-flex text-muted" data-toggle="collapse" data-parent="#coursesContained"
                                    href="#collapse-141{{ course?.id }}" aria-expanded="false"
                                    attr.aria-controls="collapse-141{{ course?.id }}">
                                    <!-- <i class="icon pt-1 px-2 fa fa-angle-down"></i> -->
                                    <!-- <i class="icon pt-1 px-2 fa" [ngClass]="{'fa-angle-up': isExpanded(course), 'fa-angle-down': !isExpanded(course)}"></i> -->
                                    <i class="icon pt-1 px-2 fa fa-angle-down pointer" *ngIf="!isExpanded(course)"></i>
                                    <i class="icon pt-1 px-2 fa fa-angle-up  pointer" *ngIf="isExpanded(course)"></i>
                                    <h5 class="pl-1 mb-0 col link">
                                        {{ course?.title }}
                                    </h5>
                                    <div *ngIf="course?.duration" class="duration"> {{ course?.duration }}m</div>
                                </a>
                            </div>
                            <div id="collapse-141{{ course?.id }}" class="collapse accordion-body" role="tabpanel"
                                aria-labelledby="header-141" data-parent="#coursesContained">
                                <div class="d-lg-flex pl-1 pb-5">
                                    <img *ngIf="course?.thumbnail" id="ytplayer" width="320" height="180" 
                                    [src]="sanitizeUrl(course?.thumbnail)" />
                                    <div class="mt-4 mt-lg-1 ml-lg-4">
                                        <section style="line-height: 1.5;">
                                            {{ course?.description }}
                                        </section>
                                        <section>
                                            <br/>
                                            <a  
                                            *ngIf="course?.moduleSlug; else searchById" 
                                            routerLink="/course/{{ course?.language }}/{{ course?.courseSlug }}/{{ course?.moduleSlug }}">
                                               <u class="highlightColor">Explore module</u> </a>
                                            <ng-template #searchById>

                                                <a style="cursor: pointer;"  (click)="navigateToModuleDetails(course)"><u class="highlightColor">Explore module </u></a>
                                            </ng-template>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Games Moules start -->
                    <!-- Games Accordion -->

                    <h2 *ngIf="bundle?.games?.length > 0" class="text-left mb-2 d-block d-md-flex justify-content-between mb-4 mt-4">
                        <div class="sub-Heading">Games modules</div>
                    </h2>
                    <div *ngIf="bundle?.games?.length > 0" class="accordion cc21-accordion" id="gamesContained" role="tablist" aria-multiselectable="true">
                        <div class="accordion-item my-2" *ngFor="let game of bundle.games">
                            <div class="accordion-header py-2 pb-3" role="tab" id="header-{{game?.id}}">
                                <a class="d-flex text-muted" data-toggle="collapse" data-parent="#gamesContained"
                                    href="#collapse-{{game?.id}}" aria-expanded="false" attr.aria-controls="collapse-{{game?.id}}">
                                    <i class="icon pt-1 px-2 fa fa-angle-down pointer" *ngIf="!isExpanded(game)"></i>
                                    <i class="icon pt-1 px-2 fa fa-angle-up pointer" *ngIf="isExpanded(game)"></i>
                                    <h5 class="pl-1 mb-0 col link">{{game?.title}}</h5>
                                </a>
                            </div>
                            <div id="collapse-{{game?.id}}" class="collapse accordion-body" role="tabpanel"
                                [attr.aria-labelledby]="'header-' + game?.id"  data-parent="#gamesContained">
                                <!-- Game Content -->
                                <div class="d-lg-flex pl-1 pb-5">
                                    <img *ngIf="game?.thumbnail" width="320" height="180" [src]="game?.thumbnail" />
                                    <div class="mt-4 mt-lg-1 ml-lg-4">
                                        <section style="line-height: 1.5;">
                                            {{game?.description}}
                                        </section>
                                        <!-- Add game-specific content here -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Games mosules ends -->

                    <!-- <h2 class="text-left mb-1 mt-4" >Reviews</h2> -->
                    <h2 class="text-left mb-1 mt-4" *ngIf="bundle?.ratings?.length > 0">Reviews ({{ bundle?.ratings?.length }})</h2>
                    <div class="container-fluid mx-auto">
                        <div class="row justify-content-left">
                            <div class="text-center mb-5 col-12">
                                <div class="card" style="border: 0;" *ngFor="let rating of bundle.ratings">
                                    <div class="row d-flex">
                                        <div>
                                            <img class="profile-pic" src="assets/images/blank-profile-picture.png">
                                        </div>
                                        <div class="d-flex flex-column">
                                            <h3 style="font-size: 18px;" class="mt-2 mb-0 text-left">{{ rating?.author }}</h3>
                                            <div>
                                                <p class="text-left">
                                                    <span class="fa fa-star star-active mr-1"></span>
                                                    <span class="fa fa-star star-active mr-1"></span>
                                                    <span class="fa fa-star star-active mr-1"></span>
                                                    <span class="fa fa-star star-active mr-1"></span>
                                                    <span class="fa fa-star star-inactive"></span>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="text-left mt-1 ml-4">
                                            <p class="text-muted pt-5 pt-sm-5">{{ rating?.publishedDate.slice(0, 10) }}</p>
                                        </div>
                                    </div>
                                    <div class="text-left mt-3 ml-5 pl-4">
                                        <p class="content" [innerHTML]="rating?.text"></p>
                                    </div>
                                    <hr>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-none d-sm-block col-3">
                <div *ngIf="isCoursesFromTeams == 'no'" class="inset card p-3 border border-primary">
                    <!-- <h5 class="">
                        Course price
                    </h5> -->
                    <ng-container *ngIf="bundle?.isPurchased !=true">
                        <section class="price d-lg-flex mt-3">
                            <div>
                                <select style="height: 45px; border: 1px solid #d3d3d3; border-radius: 5px;">
                                    <option>&nbsp;INR&nbsp;</option>
                                    <!-- <option>&nbsp;USD&nbsp;</option>
                                    <option>&nbsp;EURO&nbsp;</option> -->
                                </select>
                            </div>
                            <h3 *ngIf="bundle?.cost == 0" style="margin-top: 5px; margin-left: 10px; color: #333333;" class="pr-3 current">Free</h3>
                            <h3 *ngIf="bundle?.cost !== 0" style="margin-top: 5px;margin-left: 10px; color: #333333;" class="pr-3 current">₹{{ bundle?.cost }}</h3>
                            <!-- <s class="text-muted pt-2">₹{{ bundle?.previousPrice }}</s> -->
                        </section>
                        <section class="buy-buttons my-4" *ngIf="!shoppingCartWithoutBundleIdResult">
                            <!-- <form action="checkout" method="post" class="">
                            <input type="hidden" name="portal" value="razorpay" />
                            <input type="hidden" name="courseIds[]" value="206" /> -->
                            <!-- <a class="btn btn-primary w-100" routerLink="/my-courses/{{ bundle?.group?.id }}">Open bundle</a> -->
                            <button type="button" class="btn btn-outline-blue w-100 mb-2 add-to-cart"
                                *ngIf="!localStorage.retrieveFromLocalStorage('shopping-cart')?.includes(bundle?.id)"
                                (click)="addToCart(bundle?.id)">Add to
                                cart</button>
                            <button type="button" class="btn btn-outline-grey w-100 mb-2 add-to-cart text-muted"
                                *ngIf="localStorage.retrieveFromLocalStorage('shopping-cart')?.includes(bundle?.id)">Added
                                to
                                cart</button>
                            <form action="checkout.php" method="post">
                                <input type="hidden" name="portal" value="razorpay" />
                                <input type="hidden" name="courseIds[]" value="392" />
                                <button type="submit" class="btn btn-buy-now w-100" (click)="createRzpayOrder(bundle)">Buy
                                    now</button>
                            </form>
                            <!-- </form> -->
                        </section>
                    </ng-container>

                    <section class="features">
                        <h5>Course features</h5>
                        <ul class="text-muted list-unstyled">
                            <!-- <li>
                                <p class="features text-muted">Category -</p> <a
                                    routerLink="/course-category-details/{{ bundle?.group?.id }}">{{ bundle?.group?.name
                                    }}</a>
                            </li> -->
                            <!-- <li>
                                <p class="features-value">{{ bundle?.courses?.length }} modules</p>
                            </li> -->
                            <li>
                                <p class="features text-muted">Duration -</p>&nbsp;
                                <p class="features-value"><span *ngIf="rHours">{{ rHours }}h </span><span *ngIf="rMinutes">{{ rMinutes }}m</span></p>
                            </li>
                            <li>
                                <p class="features text-muted">Course validity -</p>&nbsp;
                                <p class="features-value">6 months</p>
                            </li>
                            <li>
                                <p class="features text-muted">Access -</p>&nbsp;
                                <p class="features-value">All web platforms</p>
                            </li>
                            <li>
                                <p class="features text-muted">Certificate of completion -</p>&nbsp;
                                <p class="features-value">Sharable</p>
                            </li>
                        </ul>
                    </section>
                    <br />
                    <section class="career-possibilities" *ngIf="bundle?.careerPossibilities?.length > 0">
                        <h5>Career possibilities</h5>
                        <ul class="text-muted list-unstyled">
                            <div *ngFor="let careerPossibility of bundle.careerPossibilities">
                                <li [innerHTML]="careerPossibility"></li>
                            </div>
                        </ul>
                    </section>
                </div>
                <div *ngIf="isCoursesFromTeams == 'yes'" class="inset card p-3 border border-primary">

                    <section class="buy-buttons my-4" *ngIf="!shoppingCartWithoutBundleIdResult">
                        <!-- *ngIf="!isPurchased" -->
                        <button type="button" *ngIf="!isPurchased" class="btn btn-buy-now w-100"
                            (click)="navigateToPlan()"> Purchase for your team </button>
                    </section>
                    <section>
                        <h3 class="box-heading">What you will get?</h3>
                        <ul>
                            <li class="box-text">Learner access to 21CC Skilled app</li>
                            <li class="box-text">Access to management platform</li>
                            <li class="box-text">Manage learner licenses</li>
                            <li class="box-text">Learner statistics</li>
                            <li class="box-text">Share per group or individually</li>
                        </ul>  
                    </section>
                </div>
            </div>
        </div>
    </div>

    <br/><br/><br/><br/>
</edu-default-layout>
