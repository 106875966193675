import { Component, EventEmitter, Input, OnInit, Output, ElementRef, ViewChild, HostListener } from '@angular/core';
import { RegistrationFormsService } from '../services/registration-forms.service';
import { FormBuilder, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomValidators } from 'src/app/shared-form/validation-service/custom-validators';
import { SharedService } from 'src/app/services/shared.service';
import { ToastrService } from 'ngx-toastr';
import * as $ from 'jquery';
import CountryCodes from 'src/app/modules/registration/country-code/countryCodes.json';

declare const _wfa_track: any;

@Component({
  selector: 'edu-get-in-touch-form',
  templateUrl: './get-in-touch-form.component.html',
  styleUrls: ['./get-in-touch-form.component.scss']
})
export class GetInTouchFormComponent implements OnInit {

  @ViewChild('formElement') formElement: ElementRef;

  @Output() isFormSubmittedEvent = new EventEmitter<boolean>();
  @Input() popUpFormEvent: boolean;

  checkboxValue: string = 'no'; // Initial value of the checkbox
  
  popUpForm: UntypedFormGroup;
  textEnteredInFirstName: string = '';
  textEnteredInLastName: string = '';
  textEnteredInAdditionalInfo: string = '';
  isMobileResolution: boolean;
  countriesData: any;
  showThankYouMessage: boolean = false;
  isFormSubmitted: boolean = false;
  disableSubmit: boolean = false;
  formChanged: boolean = false; // Track if form is touched
  phoneNumberRequired: boolean = false; // Track if phone number field is required
  phoneNumberError: string = ''; // Error message for phone number field

  countries = CountryCodes;

  constructor(
    private registrationFormsService: RegistrationFormsService,
    private sharedService: SharedService,
    private toastr: ToastrService 
  ) { }

  ngOnInit(): void {
    this.isMobileResolution = this.registrationFormsService.getResolution();
    this.initializeForm();
    this.trackFormChanges(); // Initialize tracking form changes
  }

  ngAfterViewInit(): void {
    this.initializeCountryCodeInput();
  }

  initializeCountryCodeInput(): void {
    const countryCodeInput = document.getElementById('countryCode') as HTMLInputElement;

    if (!countryCodeInput.value.startsWith('+')) {
        countryCodeInput.value = '+' + countryCodeInput.value;
    }

    countryCodeInput.addEventListener('input', (e: any) => {
        if (!e.target.value.startsWith('+')) {
            e.target.value = '+' + e.target.value.replace(/^\+/, '');
        }
    });

    countryCodeInput.addEventListener('keydown', (e: KeyboardEvent) => {
        if (e.key === 'Backspace' && countryCodeInput.selectionStart <= 1) {
            e.preventDefault();
        }
    });
  }

  hideErrorForCountryCode(){
    const hideCountryCodeErrorElement = document.getElementById("InvalidcountryCodeError");
    hideCountryCodeErrorElement.style.display = 'none';
  }

  hideErrorForEmail(){
    const hideemailErrorElement = document.getElementById("emailError");
    hideemailErrorElement.style.display = 'none';
  }

  initializeForm(): void {
    $('#webform1775809000007334001').submit((e) => {
      const isMandatory = checkMandatory1775809000007334001();
      e.preventDefault();
      if (isMandatory) {
        const formData = new FormData(e.target);
        // console.log('formData:::::::::',formData);
        const countryCode = formData.get('countryCode') as string;
         // Check if countryCode is valid
        const countryCodePattern = /^\+\d{2,3}$/;
        if (!countryCodePattern.test(countryCode)) {
          const showCountryCodeErr = document.getElementById("InvalidcountryCodeError");
          showCountryCodeErr.style.display = 'block';
          // alert('Country code must begin with + and be followed by two or three digits.');
          return; // Exit function to prevent form submission
        }

        
        const phoneNumber = formData.get('Phone') as string;
        const phoneNumberPattern = /^\d{8,11}$/;
        if (!phoneNumberPattern.test(phoneNumber)) {
          const showphoneErr = document.getElementById("invalidPhoneError");
          showphoneErr.style.display = 'block';
          // alert('Country code must begin with + and be followed by two or three digits.');
          return; // Exit function to prevent form submission
        }

        const fullPhoneNumber = countryCode + phoneNumber;
        formData.set('Phone', fullPhoneNumber);
        formData.set('LEADCF6', this.checkboxValue);

        if (typeof (_wfa_track) !== 'undefined' && _wfa_track.wfa_submit) {
          _wfa_track.wfa_submit(e);
        }
        
        const context = this; 

        const checkbox = document.getElementById('LEADCF6') as HTMLInputElement;
        const phoneField = document.getElementById('Phone') as HTMLInputElement;
        const phoneErrorElement = document.getElementById("phoneError");
        // Check if checkbox is checked and phone field is empty
        if (checkbox.checked && phoneField.value.trim() === '') {
          phoneErrorElement.innerHTML = 'Phone number cannot be empty.';
          phoneErrorElement.style.display = 'block';
          
          // alert("Phone number cannot be empty");
          return; // Exit function to prevent form submission
        }else{
          phoneErrorElement.style.display = 'none';
          $.ajax({
            url: 'https://crm.zoho.com/crm/WebToLeadForm',
            type: 'POST',
            data: formData,
            cache: false,
            contentType: false,
            processData: false,
            success: function(data) {
              context.toastr.success("Thank you! We'll be in touch soon!", 'Success', {
                timeOut: 6000,
              });
  
              const splashInfoDom = document.getElementById("wf_splash_info");
              splashInfoDom.innerText = data.actionvalue;
              const splashDom = document.getElementById("wf_splash");
              const mandatoryStarForCodeElem = document.getElementById('mandatoryStarForCode');
              const mandatoryStarForPhoneElem = document.getElementById('mandatoryStarForPhone');
              mandatoryStarForPhoneElem.style.visibility = 'hidden';
              mandatoryStarForCodeElem.style.visibility = 'hidden';
              (document.getElementById("webform1775809000007334001") as HTMLFormElement).reset();
              splashDom.style.display = '';
              setTimeout(() => {
                splashDom.style.display = 'none';
              }, 5000);
              $('.crmWebToEntityForm .formsubmit').removeAttr('disabled');
              // console.log('Form submission successful');
              context.formChanged = false; // Reset form change tracker on successful submit
            },
            error: function() {
              alert("An error occurred");
            }
          });
        }

      }
    });
    

    function checkMandatory1775809000007334001(): boolean {
      const mndFields = ['Email'];
      const fldLangVal = ['Email address'];
      const errorContainer = document.getElementById('emailError');
      
      for (let i = 0; i < mndFields.length; i++) {
        const fieldObj = (document.forms['WebToLeads1775809000007334001'] as any)[mndFields[i]];
        
        if (fieldObj) {
          if ((fieldObj.value.replace(/^\s+|\s+$/g, '')).length === 0) {
            errorContainer.innerHTML = `<p class="form-text text-danger">${fldLangVal[i]} cannot be empty.</p>`;
            errorContainer.style.display = 'block';
            fieldObj.focus();
            return false;
          }
          if (fieldObj.nodeName === 'SELECT') {
            if (fieldObj.options[fieldObj.selectedIndex].value === '-None-') {
              errorContainer.innerHTML = `<p>${fldLangVal[i]} cannot be none.</p>`;
              errorContainer.style.display = 'block';
              fieldObj.focus();
              return false;
            }
          }
        }
      }
      errorContainer.innerHTML = '';
      errorContainer.style.display = 'none';
      return validateEmail1775809000007334001();
    }

    function validateEmail1775809000007334001(): boolean {
      const form = document.forms['WebToLeads1775809000007334001'];
      const emailFld = form.querySelectorAll('[ftype=email]');
      const emailErrorElement = document.getElementById("emailError");
    
      for (let i = 0; i < emailFld.length; i++) {
        const emailVal = emailFld[i].value;
        if ((emailVal.replace(/^\s+|\s+$/g, '')).length !== 0) {
          const atpos = emailVal.indexOf('@');
          const dotpos = emailVal.lastIndexOf('.');
          if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= emailVal.length) {
            emailErrorElement.innerHTML = 'Please enter a valid email address.';
            emailErrorElement.style.display = 'block';
            emailFld[i].focus();
            return false;
          }
        } else {
          emailErrorElement.innerHTML = 'Email address cannot be empty.';
          emailErrorElement.style.display = 'block';
          emailFld[i].focus();
          return false;
        }
      }
    
      emailErrorElement.style.display = 'none';
      return true;
    }
  }

  trackFormChanges(): void {
    // Listen for changes on form fields
    $('#webform1775809000007334001 input, #webform1775809000007334001 select').on('change', () => {
      this.formChanged = true;
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    if (this.formChanged) {
      $event.returnValue = 'Changes you made will not be saved.';
    }
  }

  onCheckboxChange(event: Event): void {
    const checkbox = event.target as HTMLInputElement;
    const phoneField = document.getElementById('Phone') as HTMLInputElement;
    const phoneErrorElement = document.getElementById('phoneError');
    const mandatoryStarForCodeEle = document.getElementById('mandatoryStarForCode');
    const mandatoryStarForPhoneEle = document.getElementById('mandatoryStarForPhone');


    if (checkbox.checked) {
      this.checkboxValue = 'yes';
      mandatoryStarForPhoneEle.style.visibility = 'visible';
      mandatoryStarForCodeEle.style.visibility = 'visible';
      phoneField.setAttribute('required', 'true');
      this.phoneNumberRequired = true;
      phoneErrorElement.style.display = 'none'; // Hide error message when checkbox is checked
    } else {
      this.checkboxValue = 'no';
      mandatoryStarForPhoneEle.style.visibility = 'hidden';
      mandatoryStarForCodeEle.style.visibility = 'hidden';
      phoneField.removeAttribute('required');
      this.phoneNumberRequired = false;
    }
  }

  
  keyPressPhoneNumber(event: KeyboardEvent) {
    const hidephoneErrorElement = document.getElementById("phoneError");
    hidephoneErrorElement.style.display = 'none';
    const hideInvalidError = document.getElementById("invalidPhoneError");
    hideInvalidError.style.display = 'none';
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
  
    // Do not allow characters other than the ones in the pattern
    if (!pattern.test(inputChar)) {
      // Prevent the character from being entered
      event.preventDefault();
      return false;
    }else{
        return true;
    }
  }

}