<edu-default-layout>
    <edu-spinner *ngIf="loading"></edu-spinner>
    <header class="course-details bundle-details d-block position-relative">
        <div class="container ">
            <div class="row col-12 col-sm-8 flex-column">
                <h2 class="text-left ml-0 my-1">
                    {{ course.title }}
                </h2>
                <ul class="list-unstyled d-flex list-group-horizontal text-white mb-4">
                    <li class="unstyled-list-item">
                        <p style="color: white;"><strong>Language</strong> - English</p>
                    </li>
                </ul>
                <div class="w-100">
                    <section class="rating">
                        <span class="total">{{ course?.averageRatingScore?.toFixed(1) }}</span>&nbsp;
                        <!-- <span [ngClass]="course?.averageRatingScore?.toFixed(1) >= '1' ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                        <span [ngClass]="course?.averageRatingScore?.toFixed(1) >= '2' ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                        <span [ngClass]="course?.averageRatingScore?.toFixed(1) >= '3' ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                        <span [ngClass]="course?.averageRatingScore?.toFixed(1) >= '4' ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                        <span [ngClass]="course?.averageRatingScore?.toFixed(1) >= '5' ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span> -->
                        <div class="reviews" style="width: fit-content; display: inline;">
                            <div class="reviews-overview" style="width: fit-content; display: inline;">
                                <div class="rating-stars d-inline-block position-relative mr-2">
                                    <fieldset class="rating" style="margin-bottom: 10px;">
                                        <div class="star-ratings">
                                            <div class="fill-ratings" [style.width.%]="finalAvgPercentage">
                                                <span>★★★★★</span>
                                            </div>
                                            <div class="empty-ratings">
                                                <span>★★★★★</span>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                        <span class="text-light">({{ course?.ratings?.length }} ratings)</span>
                    </section>
                </div>
            </div>
        </div>
    </header>
    <div class="mobile-sticky d-sm-none border m-0 border-primary w-100 bg-white p-2 row">
        <div class="col-6 price">
            <!-- <h4 class="m-0 current">{{ course?.currency }}{{ course?.currentPrice }}</h4> -->
            <h4 class="m-0 current">₹{{ course?.currentPrice }}</h4>
            <!-- <span class="text-muted pt-1 pl-2">₹{{ course?.previousPrice }}</span> -->
        </div>
        <!-- <form action="checkout.php" method="post" class="p-0 col-6">
            <input type="hidden" name="portal" value="razorpay" />
            <input type="hidden" name="courseIds[]" value="392" />
            <button type="submit" class="btn btn-primary w-100 ">Buy now</button>
        </form> -->
    </div>
    <div class="container d-sm-none">
        <!-- <section class="buy-buttons my-4">
            <button type="button" class="btn btn-outline-primary w-100 mb-2 add-to-cart"
                (click)="addToCart(course?.id)">Add to cart</button>
        </section> -->
        <section class="preview">
            <h5 class="mb-2">
                Module preview
            </h5>
            <iframe id="ytplayer" type="text/html" width="320" height="180"
                src="https://www.youtube.com/embed/amCK5N-Zfe8" frameborder="0">
            </iframe>
        </section>
        <section class="features mt-4">
            <h5>Module features</h5>
            <ul class="text-muted list-unstyled">
                <!-- <li><p class="features text-muted">Category -</p> <a routerLink="/course-category-details/{{ course?.group?.id }}">{{ course?.group?.name }}</a></li> -->
                <li>
                    <p class="features text-muted">Duration -</p>&nbsp; <p class="features-value">{{ course?.duration }}
                    </p>&nbsp;<p class="features-value"><span *ngIf="rHours">{{ rHours }}h </span><span *ngIf="rMinutes">{{ rMinutes }}m</span></p> 
                    <!-- <p class="features text-muted">minutes</p> -->

                    
                </li>
                <li>
                    <p class="features text-muted">Course validity -</p>&nbsp; <p class="features-value">6 months</p>
                </li>
                <li>
                    <p class="features text-muted">Access -</p>&nbsp; <p class="features-value">All web platforms</p>
                </li>
                <li>
                    <p class="features text-muted">Certificate of completion -</p>&nbsp; <p class="features-value">
                        Sharable</p>
                </li>
            </ul>
            <br />
            <section class="career-possibilities" *ngIf="course?.careerPossibilities?.length > 0">
                <h5>Career possibilities</h5>
                <ul class="text-muted list-unstyled">
                    <div *ngFor="let careerPossibility of course.careerPossibilities">
                        <li>{{ careerPossibility }}</li>
                    </div>
                </ul>
            </section>
        </section>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-12 col-sm-8">
                <h2 class="text-left mb-2 mt-4">Description</h2>
                <p class="text-muted" [innerHTML]="course.description"></p>
                <!-- <h2 class="text-left mb-2">What you will learn </h2>
                <ul class="list-unstyled text-muted topics">
                    <div *ngFor="let topic of course.topics">
                        <li [innerHTML]="topic" style="font-size: medium;"></li>
                    </div>
                </ul>
                <h2 class="text-left mb-2" *ngIf="course.skills.length > 0">Skills you will gain</h2>
                <ul class="list-inline list-unstyled skills">
                    <div *ngFor="let skill of course.skills">
                        <li class="rounded-pill border border-primary mt-1 px-4 py-2 list-inline-item" [innerHTML]="skill"></li>
                    </div>
                </ul> -->
                <h2 class="text-left mb-1 mt-4" *ngIf="course?.ratings?.length > 0">Reviews ({{ course?.ratings?.length
                    }})</h2>
                <!-- <ul class="list-inline list-unstyled skills">
                    <div *ngFor="let rating of course.ratings">
                        <li class="rounded-pill border border-primary mt-1 px-4 py-2 list-inline-item">{{ rating }}</li>
                    </div>
                </ul> -->

                <div class="container-fluid mx-auto">
                    <div class="row justify-content-left">
                        <div class="text-center mb-5 col-12">
                            <div class="card" style="border: 0;" *ngFor="let rating of course.ratings">
                                <div class="row d-flex">
                                    <div>
                                        <img class="profile-pic" src="assets/images/blank-profile-picture.png">
                                    </div>
                                    <div class="d-flex flex-column">
                                        <h3 style="font-size: 18px;" class="mt-2 mb-0 text-left">{{ rating?.author }}
                                        </h3>
                                        <div>
                                            <p class="text-left">
                                                <span class="fa fa-star star-active mr-1"></span>
                                                <span class="fa fa-star star-active mr-1"></span>
                                                <span class="fa fa-star star-active mr-1"></span>
                                                <span class="fa fa-star star-active mr-1"></span>
                                                <span class="fa fa-star star-inactive"></span>
                                            </p>
                                        </div>
                                    </div>
                                    <div class="text-left mt-1 ml-4">
                                        <p class="text-muted pt-5 pt-sm-5">{{ rating?.publishedDate.slice(0, 10) }}</p>
                                    </div>
                                </div>
                                <div class="text-left mt-3 ml-5 pl-4">
                                    <p class="content" [innerHTML]="rating?.text"></p>
                                </div>
                                <hr>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-none d-sm-block col-4">
                <div class="inset card p-3 border border-primary">
                    <h5 class="">
                        Module price
                    </h5>
                    <section class="price d-lg-flex mt-3">
                        <h3 class="pr-3 current">₹{{ course?.currentPrice }}</h3>
                        <!-- <s class="text-muted pt-2">₹{{ course?.previousPrice }}</s> -->
                    </section>
                    <!-- <section class="buy-buttons my-4" *ngIf="!shoppingCartWithoutBundleIdResult">
                        <button type="button" class="btn btn-outline-primary w-100 mb-2 add-to-cart"
                            *ngIf="!checkShoppingCartBundles"
                            (click)="addToCart(course?.id)">Add to
                            cart</button>
                        <button type="button" class="btn btn-outline-grey w-100 mb-2 add-to-cart text-muted"
                            *ngIf="checkShoppingCartBundles">Added
                            to
                            cart</button>
                        <form action="checkout.php" method="post">
                            <input type="hidden" name="portal" value="razorpay" />
                            <input type="hidden" name="courseIds[]" value="392" />
                            <button type="submit" class="btn btn-primary w-100 " (click)="createRzpayOrder(course)">Buy
                                now</button>
                        </form>
                    </section> -->
                    <section class="preview">
                        <h5 class="mb-2">
                            Module preview
                        </h5>
                        <iframe id="ytplayer" type="text/html" width="320" height="180"
                            src="https://www.youtube.com/embed/amCK5N-Zfe8" frameborder="0">
                        </iframe>
                    </section>
                    <section class="features mt-3">
                        <h5>Module features</h5>
                        <ul class="list-unstyled">
                            <!-- <li><p class="features text-muted">Category -</p> <a routerLink="/course-category-details/{{ course?.group?.id }}">{{ course?.group?.name }}</a></li> -->
                            <li>
                                <p class="features text-muted">Duration -</p>&nbsp;<p class="features-value">{{
                                    course?.duration }}</p>&nbsp; <p class="features text-muted">minutes</p>
                            </li>
                            <li>
                                <p class="features text-muted">Course validity -</p>&nbsp; <p class="features-value">6
                                    months</p>
                            </li>
                            <li>
                                <p class="features text-muted">Access -</p>&nbsp; <p class="features-value">All web
                                    platforms</p>
                            </li>
                            <li>
                                <p class="features text-muted">Certificate of completion -</p>&nbsp; <p
                                    class="features-value">Sharable</p>
                            </li>
                        </ul>
                    </section>
                    <br />
                    <section class="career-possibilities" *ngIf="course?.careerPossibilities?.length > 0">
                        <h5>Career possibilities</h5>
                        <ul class="text-muted list-unstyled">
                            <div *ngFor="let careerPossibility of course.careerPossibilities">
                                <li [innerHTML]="careerPossibility"></li>
                            </div>
                        </ul>
                    </section>
                </div>
            </div>
        </div>
    </div>
    <br /><br /><br /><br /><br /><br />
</edu-default-layout>