const BASE_URL = 'https://jobportal.21cceducation.com/backend';
const oauthConsumerKey = '14c64843-d3ff-40b0-a17a-1af96fccc27e';
const accessKeySecret = 't54E9Fm55FOzT6gn0FIUAxOVsuneUeQH';
const  CRYPTO_IV= "5JfYL8tj2fGIyvz0Czo5Gg==";
const CRYPTO_KEY  = "+SCsNkjC8HGwco+xg2jS5g==";
const nsdcUpdateApiUrl = 'https://uat-api-fe-sid.betalaunch.in/api/webhook-public/update/user-course-data';

export const environment = {
  production: false,
  baseUrl: BASE_URL,
  frontEndUrl:'https://jobportal.21cceducation.com',
  apiUrl: BASE_URL + '/api/v1',
  oauthConsumerKey: oauthConsumerKey,
  accessKeySecret: accessKeySecret,
  CRYPTO_IV: CRYPTO_IV,
  CRYPTO_KEY: CRYPTO_KEY,
  nsdcUpdateApiUrl : nsdcUpdateApiUrl,
  // auth: {
  //   loginUrl: BASE_URL + '/api/login',
  //   refreshTokenUrl: BASE_URL + '/oauth/access_token',
  //   registrationUrl: BASE_URL + '/api/v1/registration/register',
  //   resetPasswordUrl: BASE_URL + '/api/v1/registration/resetPassword',
  //   verifyAccountUrl: BASE_URL + '/verifyAccount',
  //   setPasswordUrl: BASE_URL + '/setPassword',
  //   loginPageRoute: '/registration/start',
  //   skipAuthUrlFragments: ['/api/v1/registration']
  // },

  auth: {
    loginPath: BASE_URL + '/api/login',
    refreshTokenPath: BASE_URL + '/oauth/access_token',
    registrationPath: BASE_URL + '/api/v1/registration/register',
    resetPasswordPath: BASE_URL + '/api/v1/registration/resetPassword',
    verifyAccountUrl: BASE_URL + '/verifyAccount',
    setPasswordPath: BASE_URL + '/setPassword',
    loginPageRoute: '/registration/start-with-login',
    captchaSiteKey: '6LdAW4sqAAAAAKCFTzRa7Yw9LRJSgcfdJe3b056E',
    // Mike why is the assets directory here?
    skipAuthUrlPathPartials: ['../assets/i18n/en.json', '/api/v1/registration', '/api/v1/language']
  },
  zohoCRM: {
    xnQsjsdp: "77db35c361a5a4d6fe3c0cbd584c931a3207eced0d95d6c4e89c0ce2289cc383",
    xmIwtLD: "9c2ca281c49195c156d00537f160d4eb176ab16be043858b3dce9c40968c2ed1e3af2a633eb2f4cceb07d85792c250b5",
    actionType: "TGVhZHM=",
    
  },

  firebase : {
    apiKey: "AIzaSyDIOHOY38v9XNy85CPwIENbqvD74XWJ8xo",
    authDomain: "the-21cc-website.firebaseapp.com",
    projectId: "the-21cc-website",
    storageBucket: "the-21cc-website.appspot.com",
    messagingSenderId: "437798442938",
    appId: "1:437798442938:web:301e3d6f074028359e2f83",
    measurementId: "G-HGZ6K2Z8GN"
  }
};
