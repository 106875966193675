import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CoursesDataService } from '../courses/services/courses-data.service';
import { LocalStorageService } from 'src/app/core/local-storage.service';

@Component({
  selector: 'edu-my-courses',
  templateUrl: './my-courses.component.html',
  styleUrls: ['./my-courses.component.scss']
})
export class MyCoursesComponent implements OnInit {

  totalTime: number = 0;
  rating: string = 'unchecked';
  myCourses: any = [];
  rHours: any = 0;
  rMinutes: any = 0;
  loading: boolean = true;
  noCoursesPurchased: boolean;
  message: string = '';
  progressStatus: string = 'active';
  completedStatus: string = '';

  constructor(
    private courseDataService: CoursesDataService,
    private router: Router,
    private titleService: Title,
    private route: ActivatedRoute,
    private storageservice: LocalStorageService,
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle("My Courses - 21CC Recruitment & Training");
    this.progressOfCourses('progress');
    if (localStorage.getItem('authDetails')) {
      this.courseDataService.getEnrolledCourses().subscribe((enrolledCourses) => {
        this.myCourses = enrolledCourses;
        this.loading = false;
        this.noCoursesPurchased = false;
      },
        (err) => {
          if (err) {
            this.loading = false;
            this.noCoursesPurchased = true;
          }
        });
    } else {
      this.router.navigate(['/registration/start-with-login']);
    }
  }

  navigateToCourse(myCourse: any): void {
    const routeParams = myCourse?.moduleSlug
        ? ['/show/course', myCourse.language, myCourse.courseSlug, myCourse.moduleSlug]
        : ['/show/course', myCourse.id];

    this.router.navigate(routeParams, { relativeTo: this.route });
    this.storageservice.storeInLocalStorage("showCourseContent",JSON.stringify(myCourse) ); 
}

  calculateTotalDuration(modules: any[]) {
    let count = 0;

    for (const module of modules) {
      count += module.duration || 0;
    }

    var num = count;
    var hours = (num / 60);
    this.rHours = Math.floor(hours);
    var minutes = (hours - Math.floor(hours)) * 60;
    this.rMinutes = Math.round(minutes);
    let totalDuration = this.rHours+'h '+' '+this.rMinutes+'m';
    return totalDuration;
  }



  progressOfCourses(progressStatus: string) {
    if (progressStatus == 'progress') {
      this.message = "At the moment, you do not have any active<br/> courses. Explore our course list and<br/> continue learning.";
      this.progressStatus = 'active';
      this.completedStatus = '';
    } else {
      this.message = "You haven’t completed any courses yet.<br/> Complete or explore courses to continue.";
      this.completedStatus = 'active';
      this.progressStatus = '';
    }
  }

  openNav() {
    document.getElementById("mySidenav").style.width = "210px";
    document.body.style.backgroundColor = "rgba(0,0,0,0.4)";
  }
  
  closeNav() {
    document.getElementById("mySidenav").style.width = "0";
    document.body.style.backgroundColor = "white";
  }
  
}