import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { UserProfileService } from '../user-profile/services/user-profile.service';
import { SharedService } from 'src/app/services/shared.service';
import { LocalStorageService } from 'src/app/core/local-storage.service';
import { CartService } from '../courses/services/cart.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})

@Injectable({
  providedIn: 'root'
})

export class HomeComponent implements OnInit, AfterViewInit {
  @ViewChild('smallScreenCarousel', { static: false }) carousel: ElementRef;
  @ViewChild('getInTouchContainer') getInTouchContainer: ElementRef;

  // disableChildButtons = false;

  isFormSubmitted: boolean = false;
  showThankYouMessage: boolean = false;
  isFormSubmittedDiabled: boolean = false;
  link1: string;
  link2: string;
  showNotification: boolean = true;
  showCourseCataloguePopUp: boolean = false;
  popUpFormEvent: boolean = false;

  indiaLogos: string[] = [
    '../../../assets/images/home/I1_DB_Schenker.png',
    '../../../assets/images/home/I5_Tata_Steel.png',
    '../../../assets/images/home/I4_DP-World.png',
    '../../../assets/images/home/I7_Sarjak.png',
    '../../../assets/images/home/I3_Vedanta-Logo.png'
  ];

  netherlandsLogos: string[] = [
    '../../../assets/images/home/N5_PoA.png',
    '../../../assets/images/home/N1_port-of-Twente.png',
    '../../../assets/images/home/N3_technische-unie.jpg',
    '../../../assets/images/home/N4_Port_of_Rotterdam_logo.png',
    '../../../assets/images/home/N6_Port-of-Moerdijk.png',
    '../../../assets/images/home/N8_openij-logo.jpg',
    '../../../assets/images/home/I2_Deutsche_Giz.png',
    '../../../assets/images/home/N2_Topsector_logestiek.png',
    '../../../assets/images/home/N7_Emons-logo.png',
    '../../../assets/images/home/I6_GFA-Consulting-Group.png',
    '../../../assets/images/home/N9_LEVVEL.png',
    '../../../assets/images/home/N10_waterstaat.png'
  ];

  whysData: any = [
    {
      image: 'assets/images/home/why21cceducation/3.png',
      paragraph:
        'Our expert content developers can create customised e-learning offerings based on your specific requirements. Our training content can be translated into any language of your choice.',
    },
    {
      image: 'assets/images/home/why21cceducation/2.png',
      paragraph:
        'Redefining the very concept of learning, our library of games makes difficult to understand concepts very easy to learn and commit to  memory.',
    },
    {
      image: 'assets/images/home/why21cceducation/1.png',
      paragraph:
        'We can help mitigate resource time and attrition rates, enhance productivity and reduce cost per employee while you train your employees in our app\'s intuitive, immersive and gamified environment.',
    },
  ];

  productsData: any = [
    {
      image: 'assets/images/home/products/1.png',
      heading: '21CC Skilled',
      paragraph:
        '21CC Skilled provides intuitive access to games, learning modules, videos and animations on any device, making learning easier and much more fun!',
      btn1: 'See more',
      btn2: 'Watch video',
    },
    {
      image: 'assets/images/home/products/2.png',
      heading: 'E-learning modules',
      paragraph:
        'Get the skills you need to start a new career or upskill to enhance your career in logistics with our course bundles',
      btn1: 'See more',
    },
    {
      image: 'assets/images/home/products/3.png',
      heading: 'Corporate Academy',
      paragraph:
        'Train and develop your team in the fast-paced world of logistics. The 21CC Corporate Academy offers tailored learning solutions that will inspire and develop your team',
      btn1: 'See more',
    },
  ];

  constructor(
    private userProfileService: UserProfileService,
    private titleService: Title,
    private router: Router,
    private elementRef: ElementRef,
    public sharedService: SharedService,
    private localStorageService: LocalStorageService,
    private cartService: CartService
  ) { }

  ngOnInit(): void {
    this.cartService.setDisplayCartIcon(false);
    this.localStorageService.removeFromLocalStorage('fffaiWai');
    this.localStorageService.removeFromLocalStorage('activeTabLibrary');
    this.sharedService.disableButtons = false;
    // this.disableChildButtons=false;
    this.titleService.setTitle("Home - 21CC Recruitment & Training");

    if (localStorage.getItem('authDetails')) {
      this.userProfileService.init();
    }
    const notificationShown = sessionStorage.getItem('notificationShown');
    if (notificationShown) {
      this.showNotification = false;
    } else {
      this.showNotification = true;
      sessionStorage.setItem('notificationShown', 'true');
    }
    var userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod'];
    if (
      macosPlatforms.indexOf(platform) !== -1 ||
      iosPlatforms.indexOf(platform) !== -1
    ) {
      this.link1 = 'https://apps.apple.com/us/app/the-21cc-app/id1512910768';
    } else if (
      windowsPlatforms.indexOf(platform) !== -1 ||
      /Android/.test(userAgent)
    ) {
      this.link2 =
        'https://play.google.com/store/apps/details?id=com.education21cc.logistics&hl=en&gl=US&pli=1';
    }

    if (localStorage.getItem("popUpFormSubmitted")) {
      this.isFormSubmitted = true;
      // this.disableChildButtons = true;
      this.isFormSubmittedDiabled = true;
      this.sharedService.disableButtons = true;
    }

    //auto scroll to get-in-touch form when certain url hit in browser address bar.
    if (this.router.url.includes('getintouch')) {
      if (!localStorage.getItem("popUpFormSubmitted")) {
        setTimeout(() => {
          this.scrollToSection();
        }, 500);
      }
    }
  }

  ngAfterViewInit() {
    // Enable swipe gestures on the carousel in future(need to install jquery)
    // const carouselElement = this.carousel.nativeElement;
    // $(carouselElement).on("touchstart", function(event) {
    //   var xClick = event.originalEvent.touches[0].pageX;
    //   $(this).one("touchmove", function(event) {
    //     var xMove = event.originalEvent.touches[0].pageX;
    //     if (Math.floor(xClick - xMove) > 5) {
    //       $(this).carousel("next");
    //     } else if (Math.floor(xClick - xMove) < -5) {
    //       $(this).carousel("prev");
    //     }
    //   });
    //   $(this).on("touchend", function() {
    //     $(this).off("touchmove");
    //   });
    // });
  }

  closeNotification() {
    this.showNotification = false;
  }

  redirectToGizRegistrationForm() {
    this.router.navigate([]).then(result => { window.open('/skills_4_riders_registration_form', '_blank'); });
  }

  showCourseCatalogue() {
    if (localStorage.getItem("popUpFormSubmitted")) {
      this.handleButtonClickToGA('downloadCourseCatalogueFile');
      this.downloadCourseCatalogue();
    } else {
      this.handleButtonClickToGA('downloadCourseCatalogue');
      this.showCourseCataloguePopUp = true;
    }
  }

  handleButtonClickToGA(parameter: string) {
    // Send the button click event to Google Analytics
    switch (parameter) { 
      case 'downloadCourseCatalogue':
        gtag('event', 'click', {
          event_category: 'download_course_catalogue_button_click',
          event_label: 'Download Course Catalogue Button Click',
        });
        break;
      case 'downloadCourseCatalogueFile':
        gtag('event', 'file_download', {
          event_category: 'download_course_catalogue_button_click_file_download',
          event_label: 'Download Course Catalogue File Download',
        });
        break;
    }
  }

  closeCourseCataloguePopUp(value: boolean) {
    this.showCourseCataloguePopUp = value;
  }

  scrollToSection() {
    console.log('Scrolling to section');
    const section = this.elementRef.nativeElement.querySelector('#get-in-touch-container');
    section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    section.style.marginTop = "100px";
  }
  downloadCourseCatalogue() {
    window.open('../../../../assets/documents/catalogue-21cc-february-2023.pdf', '_blank');
    this.showCourseCataloguePopUp = false;
  }

  closePopUp() {
    this.showCourseCataloguePopUp = false;
  }

  isFormSubmittedEventReceived(boolValue: boolean) {
    this.isFormSubmitted = boolValue;
  }

}
