<nav class="navbar navbar-expand-md bg-white w-100 shadow-below">
  <a class="logo mb-2" routerLink="/home"></a>
  <div class="d-md-none ml-auto mb-2" *ngIf="isLoggedIn">
    <a class="nav-link position-relative shopping-cart mr-5" routerLink="/checkout">
      <img src="assets/images/cart.svg" alt="box" />
      <div class="indicator badge-pill badge-danger small d-none" *ngIf="totalItems == 0">2</div>
      <div class="indicator badge-pill badge-danger small" *ngIf="totalItems > 0">{{ totalItems }}</div>
    </a>
  </div>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#header" aria-controls="header"
    aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="header">
    <!-- <ul class="navbar-nav ml-auto" style="width: 100%;" id="items-for-navbar"> -->
      <ul class="navbar-nav ml-auto align-items-center" style="width: 100%;" id="items-for-navbar">
      <li class="d-flex d-md-none mb-5 mobile-loggedin" style="margin: auto; margin-top: 5%;">
        <ng-container *transloco="let t; read: 'user-profile.profile-form'">
          <ng-container *ngIf="(userProfile$ | async) as userProfile">
            <edu-profile-card [employeeNumber]="user.employeeNumber" [companyLogo]="user.companyLogo"
              *ngIf="userProfile.user as user">
              <div card-header class="flex">
                <div class="image-container">
                  <div>
                    <!-- <img [hidden]="!(avatarImageSrc$ | async)" [src]="(avatarImageSrc$ | async)"
                      alt="current users avatar"> -->
                      <img src="images/icons/icon-hamburger-new.svg" alt="current users avatar"
                (click)="checkRole()">
                    </div>
                </div>
                <div class="username-container">
                  <h2>{{user.firstName}} {{user.lastName}}</h2>
                </div>
                <div class="companyLogo-container">
                  <img class="companyLogo" src={{user.companyLogo}} onerror="this.style.display='none';">
                </div>
                <div class="employeeNumber-container">
                  <span class="employeeNumber">{{ user.employeeNumber }}</span>
                </div>
              </div>
              <div card-content>
                <div class="information">
                  <div class="item">
                    <div class="icon-mail" [inlineSVG]="'assets/images/icons/icon-mail.svg'"></div>
                    <span>{{user.email}}</span>
                  </div>
                  <div class="item">
                    <div [inlineSVG]="'assets/images/icons/icon-location.svg'"></div>
                    <span>{{countryName}}</span>
                  </div>
                  <div class="item" *ngIf="user.birthDate">
                    <div [inlineSVG]="'assets/images/icons/icon-calendar.svg'"></div>
                    <span>{{(user.birthDate) | translocoDate}}</span>
                  </div>
                  <div class="item" *ngIf="user.telephone">
                    <div [inlineSVG]="'assets/images/icons/icon-phone.svg'"></div>
                    <span>{{user.telephone}}</span>
                  </div>
                  <div class="item" routerLink="/user-profile">
                    <div [inlineSVG]="'assets/images/icons/icon-profile.svg'"></div>
                    <span class="visit-profile"><b>Visit full profile</b></span>
                  </div>
                </div>
                <div class="user-description">
                  <div class="item" *ngIf="user.telephone">
                    <div class="aboutYou" [inlineSVG]="'assets/images/icons/icon-about-you.svg'"></div>
                    <span>{{user.aboutYou}}</span>
                  </div>
                  <!-- <p>{{user.aboutYou}}</p> -->
                </div>
              </div>
            </edu-profile-card>
          </ng-container>
        </ng-container>
      </li>
      <li class="nav-item mt-1" [ngClass]="{'selected': home.isActive}" style="height: 72px;" routerLink="/home"  routerLinkActive="active" #home="routerLinkActive">
        <a class="nav-link" id="home" style="margin-top: 20px;">Home</a>
      </li>      
      
      <li class="nav-item mt-1 dropdown" [ngClass]="{'color': about.isActive ? '#189CD8' : '#262D5F'}">
        <a class="nav-link dropdown-toggle" href="#" id="aboutDropdown" data-toggle="dropdown" aria-haspopup="true"
          aria-expanded="false">About Us</a>
        <ul class="dropdown-menu about-us" aria-labelledby="aboutDropdown">
          <li class="nav-item" [ngClass]="{'color': who.isActive ? '#189CD8' : '#262D5F'}" routerLink="/who-we-are" routerLinkActive="active" #who="routerLinkActive">
            <a class="nav-link">Who We Are</a>
          </li>
          <li class="nav-item" [ngClass]="{'color': products.isActive ? '#189CD8' : '#262D5F'}"
            routerLink="/products" routerLinkActive="active" #products="routerLinkActive">
            <a class="nav-link">Our Offering</a>
          </li>
          <li class="nav-item" [ngClass]="{'color': ourApproach.isActive ? '#189CD8' : '#262D5F'}"
            routerLink="/our-approach" routerLinkActive="active" #ourApproach="routerLinkActive">
            <a class="nav-link">Our Approach</a>
          </li>
          <!-- <li class="nav-item" [ngClass]="{'color': csr.isActive ? '#189CD8' : '#262D5F'}"
            routerLink="/csr" routerLinkActive="active" #csr="routerLinkActive">
            <a class="nav-link">CSR</a>
          </li> -->
        </ul>
      </li>
      <li class="nav-item mt-1" [ngClass]="{'selected': courses.isActive, 'highlight': isSelectPlanActive}" style="height: 72px;" routerLink="/library"
        routerLinkActive="active" #courses="routerLinkActive">
        <a class="nav-link" id="courses" style="margin-top: 20px;">Library</a>
      </li>
      <li class="nav-item mt-1" [ngClass]="{'selected': blog.isActive}" style="height: 72px;" routerLink="/blog"
        routerLinkActive="active" #blog="routerLinkActive">
        <a class="nav-link" id="blog" style="margin-top: 20px;">Blog</a>
      </li>
      <li class="nav-item mt-1 dropdown" [ngClass]="{'color': project.isActive ? '#189CD8' : '#262D5F'}">
        <a class="nav-link dropdown-toggle" href="#" id="aboutproject" data-toggle="dropdown" aria-haspopup="true"
          aria-expanded="false">Projects</a>
        <ul class="dropdown-menu project" aria-labelledby="aboutproject">
          <li class="nav-item" [ngClass]="{'color': csr.isActive ? '#189CD8' : '#262D5F'}"
            routerLink="/csr" routerLinkActive="active" #csr="routerLinkActive">
            <a class="nav-link">CSR</a>
          </li>
          <li class="nav-item" [ngClass]="{'color': port.isActive ? '#189CD8' : '#262D5F'}" routerLink="/ports" routerLinkActive="active" #port="routerLinkActive">
            <a class="nav-link">Ports</a>
          </li>
        </ul>
      </li>
      <li class="nav-item mt-1" [ngClass]="{'selected': events.isActive}" style="height: 72px;" routerLink="/events"
        routerLinkActive="active" #events="routerLinkActive">
        <a class="nav-link" id="events" style="margin-top: 20px;">Events</a>
      </li>
      <li class="nav-item mt-1" [ngClass]="{'selected': contact.isActive}" style="height: 72px;" routerLink="/contact-us" (click)="scrollToTop()"
        routerLinkActive="active" #contact="routerLinkActive">
        <a class="nav-link" id="contact" style="margin-top: 20px;" (click)="scrollToTop()">Contact Us</a>
        <!-- <div class="selected-bar d-none d-md-block" *ngIf="contact.isActive"></div> -->
      </li>
      <li class="nav-item d-md-none" [ngClass]="{'color': contact.isActive ? '#189CD8' : '#262D5F'}">
        <ng-container *transloco="let t; read: 'user-settings'">
          <div class="settings-container">
            <div *ngIf="(role$ | async)?.includes('ROLE_JOB_SEEKER')" class="w-100 mb-4 settings-option"
              routerLink="/user-settings/invitation-code">
              <!-- <span class="icon" [inlineSVG]="'assets/images/icons/icon-envelope.svg'"></span> -->
              <a class="nav-link">Invitation code</a>
            </div>
            <div class="w-100 mt-2 settings-option"  [routerLink]="'/registration/start-with-login'" *ngIf="!isLoggedIn">
              <a class="nav-link">Log in</a>
            </div>
            <div class="w-100 mt-4 settings-option" [routerLink]="'/registration/start-with-signup'" *ngIf="!isLoggedIn">
              <a class="nav-link">Sign up</a>
            </div>
            <div class="w-100 mt-4 settings-option" (click)="logout()" *ngIf="isLoggedIn">
              <!-- <span class="icon logout" [inlineSVG]="'assets/images/icons/icon-logout.svg'"></span> -->
              <a class="nav-link">Log out</a>
            </div>
          </div>
        </ng-container>
      </li>
      <br />
      <li class="nav-item d-md-none dropdown" *ngIf="isLoggedIn">
        <a class="nav-link dropdown-toggle" href="#" id="dropdownMenuLink3" data-toggle="dropdown" aria-haspopup="true"
          aria-expanded="false">More</a>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink3" style="margin-right: 5%;">
          <li class="nav-item mr-md-3" routerLink="/industry/0">
            <a class="nav-link">Fantasy Map</a>
          </li>
          <li class="nav-item mr-md-3" routerLink="/careers">
            <a class="nav-link">Careers</a>
          </li>
          
          <li class="nav-item mr-md-3" routerLink="/my-courses">
            <a class="nav-link">My Courses</a>
          </li>
          <li class="nav-item mr-md-3" routerLink="/user-certificates">
            <a class="nav-link">My Certificates</a>
          </li>
          <li class="nav-item mr-md-3" routerLink="/skills">
            <a class="nav-link">Skills Passport</a>
          </li>

        </ul>
        <br /><br /><br /><br />
      </li>
      <br/><br/><br/><br/><br/>
      <!-- </ul> -->

      <!-- <ul class="navbar-nav ml-2"> -->

          <li class="nav-item d-none d-md-block ml-auto" style="border-bottom: none; padding-top: 2px;" *ngIf="isLoggedIn">
            <!-- *ngIf="displayCartIcon" -->
            <a class="nav-link position-relative shopping-cart" routerLink="/checkout">
              <img src="assets/images/cart.svg" alt="box" />
              <div class="indicator badge-pill badge-danger small d-none" *ngIf="totalItems == 0">2</div>
              <div class="indicator badge-pill badge-danger small" *ngIf="totalItems > 0">{{ totalItems }}</div>
            </a>
          </li>
    
          <li class="nav-item mt-1 ml-auto d-none d-sm-flex" style="border-bottom: none;" *ngIf="!isLoggedIn">
            <!-- *ngIf="displayCartIcon" -->
            <a class="nav-link position-relative shopping-cart" style="padding: 9px 4px;" routerLink="/checkout">
              <img src="assets/images/cart.svg" alt="box" />
              <div class="indicator badge-pill badge-danger small d-none" *ngIf="totalItems == 0">2</div>
              <div class="indicator badge-pill badge-danger small" *ngIf="totalItems > 0">{{ totalItems }}</div>
            </a>
          </li>
          <li class="nav-item mt-1" [ngClass]="{'selected': login.isActive}"  style="height: 72px;" routerLink="/registration/start-with-login" 
            routerLinkActive="active" #login="routerLinkActive"  *ngIf="!isLoggedIn">
            <ng-container *transloco="let t; read: 'user-settings'">
              <a class="nav-link" id="login" style="margin-top: 20px;" >Log in</a>
            </ng-container>
          </li>
         
          <li class="nav-item mt-1" [ngClass]="{'selected': signup.isActive}" style="border-bottom: none;" routerLink="/registration/start-with-signup" 
          routerLinkActive="active" #signup="routerLinkActive" *ngIf="!isLoggedIn">
          <ng-container *transloco="let t; read: 'user-settings'">
            <a class="register-class btn ml-2" id="signup" style="height: 44px;">Sign up</a>
          </ng-container>
          </li>
          
         

      <li class="nav-item empty user d-none d-md-block" *ngIf="isLoggedIn" style="border-bottom: none;">
        <a data-toggle="collapse" data-target="#userFoldOut" aria-expanded="false" aria-controls="userFoldOut">
          <div class="avatar-container">
            <div>
              <!-- <img [hidden]="!(avatarImageSrc$ | async)" [src]="(avatarImageSrc$ | async)" alt="current users avatar"
                (click)="checkRole()"> -->
                <img src="images/icons/icon-hamburger-new.svg" alt="current users avatar"
                (click)="checkRole()">
            </div>
          </div>
        </a>
      </li>
    </ul>
  </div>
</nav>
<div class="collapse row col-3 bg-white" id="userFoldOut">
  <div class="wrapper pt-3">
    <ng-container *transloco="let t; read: 'user-profile.profile-form'">
      <ng-container *ngIf="(userProfile$ | async) as userProfile">
        <edu-profile-card [employeeNumber]="user.employeeNumber" [companyLogo]="user.companyLogo"
          *ngIf="userProfile.user as user">
          <div card-header class="flex">
            <div class="image-container">
              <div>
                <!-- <img [hidden]="!(avatarImageSrc$ | async)" [src]="(avatarImageSrc$ | async)"
                  alt="current users avatar"> -->
                  <img src="images/icons/icon-hamburger-new.svg" alt="current users avatar"
                (click)="checkRole()">
                </div>
            </div>
            <div class="username-container">
              <h2>{{user.firstName}} {{user.lastName}}</h2>
            </div>
            <div class="companyLogo-container">
              <img class="companyLogo" src={{user.companyLogo}} onerror="this.style.display='none';">
            </div>
            <div class="employeeNumber-container">
              <span class="employeeNumber">{{ user.employeeNumber }}</span>
            </div>
          </div>
          <div card-content>
            <div class="information">
              <div class="item">
                <div class="icon-mail" [inlineSVG]="'assets/images/icons/icon-mail.svg'"></div>
                <span>{{user.email}}</span>
              </div>
              <div class="item">
                <div [inlineSVG]="'assets/images/icons/icon-location.svg'"></div>
                <span>{{countryName}}</span>
              </div>
              <div class="item" *ngIf="user.birthDate">
                <div [inlineSVG]="'assets/images/icons/icon-calendar.svg'"></div>
                <span>{{(user.birthDate) | translocoDate}}</span>
              </div>
              <div class="item" *ngIf="user.telephone">
                <div [inlineSVG]="'assets/images/icons/icon-phone.svg'"></div>
                <span>{{user.telephone}}</span>
              </div>
              <div class="item" routerLink="/user-profile">
                <div [inlineSVG]="'assets/images/icons/icon-profile.svg'"></div>
                <span class="visit-profile"><b>Visit full profile</b></span>
              </div>
            </div>
            <div class="user-description">
              <div class="item" *ngIf="user.telephone">
                <div class="aboutYou" [inlineSVG]="'assets/images/icons/icon-about-you.svg'"></div>
                <span>{{user.aboutYou}}</span>
              </div>
              <!-- <p>{{user.aboutYou}}</p> -->
            </div>
          </div>
        </edu-profile-card>
      </ng-container>
    </ng-container>
    <br />
    <ng-container *ngIf="this.userRole != 'ROLE_COMPANY_ADMIN'">
      <div class="w-100 mt-1" [ngClass]="{'color': myCourses.isActive ? '#189CD8' : '#262D5F'}" routerLink="/my-courses"
        routerLinkActive="active" #myCourses="routerLinkActive">
        <a class="nav-link">My Courses</a>
      </div>
      <div class="w-100 mt-1" [ngClass]="{'color': myCourses.isActive ? '#189CD8' : '#262D5F'}" routerLink="/careers"
        routerLinkActive="active" #myCourses="routerLinkActive">
        <a class="nav-link">Careers</a>
      </div>
      <div class="w-100 mt-1" [ngClass]="{'color': fantasyMap.isActive ? '#189CD8' : '#262D5F'}" routerLink="/industry/0"
        routerLinkActive="active" #fantasyMap="routerLinkActive">
        <a class="nav-link">Fantasy Map</a>
      </div>
      <div class="w-100 mt-1" [ngClass]="{'color': certificates.isActive ? '#189CD8' : '#262D5F'}"
        routerLink="/user-certificates" routerLinkActive="active" #certificates="routerLinkActive">
        <a class="nav-link">My Certificates</a>
      </div>
      <div class="w-100 mt-1" [ngClass]="{'color': skills.isActive ? '#189CD8' : '#262D5F'}" routerLink="/skills"
        routerLinkActive="active" #skills="routerLinkActive">
        <a class="nav-link">Skills Passport</a>
      </div>
      <ng-container *transloco="let t; read: 'user-settings'">
        <div class="settings-container">
          <div *ngIf="(role$ | async)?.includes('ROLE_JOB_SEEKER')" class="w-100 mt-1 settings-option"
            routerLink="/user-settings/invitation-code">
            <!-- <span class="icon" [inlineSVG]="'assets/images/icons/icon-envelope.svg'"></span> -->
            <a class="nav-link">Invitation code</a>
          </div>
          <div class="w-100 mt-1 settings-option" [routerLink]="'/user-settings/set-password'">
            <!-- <span class="icon" [inlineSVG]="'assets/images/icons/icon-key.svg'"></span> -->
            <a class="nav-link">{{t(userIsUnverified ? 'set-password' : 'change-password')}}</a>
          </div>

          <div class="w-100 mt-1 settings-option" [routerLink]="'/registration/start-with-login'" *ngIf="!isLoggedIn">
            <a class="nav-link">Log in</a>
          </div>
          <div class="w-100 mt-1 settings-option" [routerLink]="'/registration/start-with-signup'" *ngIf="!isLoggedIn">
            <a class="nav-link">Sign up</a>
          </div>
          <div class="w-100 mt-1 settings-option" (click)="logout()" *ngIf="isLoggedIn">
            <!-- <span class="icon logout" [inlineSVG]="'assets/images/icons/icon-logout.svg'"></span> -->
            <a class="nav-link">Log out</a>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="this.userRole == 'ROLE_COMPANY_ADMIN'">
      <div class="w-100 mt-1" [ngClass]="{'color': myCourses.isActive ? '#189CD8' : '#262D5F'}" routerLink="/admin-registration/manage-admin-accounts"
        routerLinkActive="active" #myCourses="routerLinkActive">
        <a class="nav-link">Manage admins</a>
      </div>
      <div class="w-100 mt-1" [ngClass]="{'color': myCourses.isActive ? '#189CD8' : '#262D5F'}" routerLink="/admin-registration/manage-learners"
        routerLinkActive="active" #myCourses="routerLinkActive">
        <a class="nav-link">Manage learners</a>
      </div>
      <div class="w-100 mt-1" [ngClass]="{'color': fantasyMap.isActive ? '#189CD8' : '#262D5F'}" routerLink="/admin-registration/billing"
        routerLinkActive="active" #fantasyMap="routerLinkActive">
        <a class="nav-link">Billing</a>
      </div>
      <div *ngIf="isFFFAIWAI" class="w-100 mt-1" [ngClass]="{'color': viewasLearner.isActive ? '#189CD8' : '#262D5F'}" routerLink="/admin-registration/industry/0"
        routerLinkActive="active" #viewasLearner="routerLinkActive">
        <a class="nav-link">View as learner</a>
      </div>
      <ng-container *transloco="let t; read: 'user-settings'">
        <div class="settings-container">
          <div *ngIf="(role$ | async)?.includes('ROLE_JOB_SEEKER')" class="w-100 mt-1 settings-option"
            routerLink="/user-settings/invitation-code">
            <!-- <span class="icon" [inlineSVG]="'assets/images/icons/icon-envelope.svg'"></span> -->
            <a class="nav-link">Invitation code</a>
          </div>
          <!-- <div class="w-100 mt-1 settings-option" [routerLink]="'/user-settings/set-password'">
            <a class="nav-link">{{t(userIsUnverified ? 'set-password' : 'change-password')}}</a>
          </div> -->

          <div class="w-100 mt-1 settings-option" [routerLink]="'/registration/start-with-login'" *ngIf="!isLoggedIn">
            <a class="nav-link">Log in</a>
          </div>
          <div class="w-100 mt-1 settings-option" [routerLink]="'/registration/start-with-signup'" *ngIf="!isLoggedIn">
            <a class="nav-link">Sign up</a>
          </div>
          <div class="w-100 mt-1 settings-option" (click)="logout2()" *ngIf="isLoggedIn">
            <!-- <span class="icon logout" [inlineSVG]="'assets/images/icons/icon-logout.svg'"></span> -->
            <a class="nav-link">Log out</a>
          </div>
        </div>
      </ng-container>
    </ng-container>
    
  </div>
</div>