<edu-default-layout>
    <br/><br/><br/><br/><br/>
<div class="container pb-5 mt-5">
    <br><br>
    <div class="main container ">
        <div class="pb-4">
            <img class="product_image" src="assets/images/products/product/21CC_App.jpg" alt="21CCApp">
        </div>
        <div class="pb-4 p-5">
            <h5>21CC Skilled: </h5>
            <p>21CC Skilled is designed to provide the learner an intuitive access to gamified learning modules,
                films and animations on the move.
            </p>
            <p>21CC Skilled is a learning app is built on the principle that makes learning more engaging and
                fun making it easier to learn.</p>
            <p class="button"> <a routerLink="/contact-us"><button class="btn btn-primary mt-2">Enquire now</button></a>&nbsp; <button
                    type="button" class="btn btn-primary mt-2" data-toggle="modal" data-target="#exampleModalCenter">
                    Watch video
                </button></p>
            <!-- Modal -->
            <div class="modal fade " id="exampleModalCenter" tabindex="-1" role="dialog"
                aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered d-flex justify-content-center" role="document">
                    <div class="modal-content">
                        <div class="modal-body">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <div class="embed-responsive embed-responsive-16by9">
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/tg92djRMBfE"
                                    title="YouTube video player" frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--  -->
        </div>
    </div>
    <div class="main-reverse container">
        <div class="pb-4"><img class="product_image" src="assets/images/products/product/Modules_11zon.jpg" alt="image">
        </div>
        <div class="pb-4 p-5">
            <h5>E-learning modules</h5>
            <p>Custom made e-learning modules help to onboard and train (new) employees quickly and in a fun way.</p>
            <p class="button"><a routerLink="/library"><button class="btn btn-primary">Check courses</button></a></p>
        </div>
    </div>
    <div class="main container">
        <div class="pb-4"><img class="product_image" src="assets/images/products/product/Corporate_Academy_11zon.jpg"
                alt="image"></div>
        <div class="pb-4 p-5">
            <h5><b>Corporate Academy</b></h5>
            <p>The 21CC Corporate Academy makes it easy for you to quickly onboard your new employees and to keep your
                current employee well informed about all important processes and procedures.</p>
            <p class="button"> <a href="https://21cceducation.com/industry/0" target="_blank"><button
                        class="btn btn-primary">Enquire now</button></a></p>
        </div>
    </div>
    <div class="main-reverse container">
        <div class="pb-4"><img class="product_image" src="assets/images/products/product/Games_11zon.jpg" alt="image">
        </div>
        <div class="pb-4 p-5">
            <h5><b>Games</b></h5>
            <p>With the right well-designed games, employees can be trained faster, deliver more quality and work more
                efficiently. The games that we make together with our customers are aimed at achieving those learning
                goals that are important in practice. Games allow employees to practice, make mistakes and learn from
                them, understand the different parts of their work faster and gain awareness of their role in the whole.
            </p>
            <p class="button"> <a routerLink="/contact-us"><button class="btn btn-primary">Enquire now</button></a></p>
        </div>
    </div>
    <div class="main container">
        <div class="pb-4"><img class="product_image" src="assets/images/products/product/BoTM_game_Logo.svg"
                alt="image"></div>
        <div class="pb-4 p-5">
            <h5><b>Business On The Move</b></h5>
            <p>Business on the Move is a fun, educational and award-winning board game that is in the process of being
                digitised by 21CC. This will make it possible to play this multiplayer game with colleagues around the
                globe. While running your own global logistics company you’ll learn about business, global supply
                chains, and even a little about the carbon footprint you leave behind.</p>
            <p class="button"><a
                    href="assets/images/products/product/Game brochure - business on the move_2.pdf" target="_blank"><button
                        class="btn btn-primary">Download brochure</button></a></p>
        </div>
    </div>
</div>
</edu-default-layout>