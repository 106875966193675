<edu-default-layout>
    <div class="gradient-color">
        <div class="container d-flex align-items-center vh-150 margin-top-7-percent">
            <div class="fill-height pt-md-5 d-flex align-items-center">
                <div class="container">
                    <h1 class="row col text-white pt-5 mt-2">Careers</h1>
                </div>
            </div>
        </div>
        <br /><br /><br />
        <br /><br /><br />
    </div>
    <div class="apply-career">
        <div class="position-relative container content-center">
            <nav class="nav tabnav inverted" id="categoryNav" role="tablist">
                <a class="nav-link pb-3 px-1 mr-sm-3 text-white active" data-toggle="tab" data-category="all" role="tab"
                    aria-controls="category-all" aria-selected="true\" id="all-tab" (click)="assignName('All')">All</a>
                <a class="nav-link pb-3 px-1 mr-sm-3 text-white" data-toggle="tab" data-category="creative" role="tab"
                    aria-controls="category-creative" aria-selected="true\" id="creative-tab"
                    (click)="assignName('Creative')">Creative</a>
                <a class="nav-link pb-3 px-1 mr-sm-3 text-white" data-toggle="tab" data-category="web-developer"
                    role="tab" aria-controls="category-web-developer" aria-selected="true\" id="web-developer-tab"
                    (click)="assignName('Web Developer')">Web Developer</a>
            </nav>
        </div>
        <app-career-card [tabName]="tabName"></app-career-card>
    </div>
    <br /><br/><br/><br/>
    <!-- main app container -->
    <div class="jumbotron">
        <div class="container">
            <div class="row">
                <div class="col-md-10">
                    <h2><b>Are you interested in other job positions?</b></h2>
                    <br /><br /><br />
                    <form name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
                        <div class="form row d-flex justify-content-between">
                            <div class="form-group col-sm-5">
                                <label for="firstName">First Name</label>
                                <input type="text" class="form-control" name="firstName" [(ngModel)]="model.firstName"
                                    #firstName="ngModel" [ngClass]="{ 'is-invalid': f.submitted && firstName.invalid }"
                                    required />
                                <div *ngIf="f.submitted && firstName.invalid" class="invalid-feedback">
                                    <div *ngIf="firstName.errors.required">First Name is required</div>
                                </div>
                            </div>
                            <div class="form-group col-sm-5">
                                <label for="lastName">Last Name</label>
                                <input type="text" class="form-control" name="lastName" [(ngModel)]="model.lastName"
                                    #lastName="ngModel" [ngClass]="{ 'is-invalid': f.submitted && lastName.invalid }"
                                    required />
                                <div *ngIf="f.submitted && lastName.invalid" class="invalid-feedback">
                                    <div *ngIf="lastName.errors.required">Last Name is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="form row d-flex justify-content-between">
                            <div class="form-group col-sm-5">
                                <label for="email">Email Address</label>
                                <input type="text" class="form-control" name="email" [(ngModel)]="model.email"
                                    #email="ngModel" [ngClass]="{ 'is-invalid': f.submitted && email.invalid }" required
                                    email />
                                <div *ngIf="f.submitted && email.invalid" class="invalid-feedback">
                                    <div *ngIf="email.errors.required">Email is required</div>
                                    <div *ngIf="email.errors.email">Email must be a valid email address</div>
                                </div>
                            </div>
                            <div class="form-group col-sm-5">
                                <label for="jobRole">What job are you Interested in?</label>
                                <select name="jobRole" class="form-control" [(ngModel)]="model.jobRole" #jobRole="ngModel" [ngClass]="{ 'is-invalid': f.submitted && jobRole.invalid }" required > 
                                    <option *ngFor="let job of jobList">{{job}}</option>  
                                </select>
                                <div *ngIf="f.submitted && jobRole.invalid" class="invalid-feedback">
                                    <div *ngIf="jobRole.errors.required">Job name is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="description">Tell us about yourself and why you are interested</label>
                            <textarea type="text" rows="5" class="form-control" name="description"
                                [(ngModel)]="model.description" #description="ngModel"
                                [ngClass]="{ 'is-invalid': f.submitted && description.invalid }" required></textarea>
                            <div *ngIf="f.submitted && description.invalid" class="invalid-feedback">
                                <div *ngIf="description.errors.required">Job description is required</div>
                            </div>
                        </div>
                        {{model.file}}
                        <div class="form row d-flex justify-content-between">
                            <div class="form-group col-sm-5 col">
                                <input type="file" class="form-control" name="file" [(ngModel)]="model.file" #fileName
                                    [ngClass]="{ 'is-invalid': f.submitted }" hidden required />
                                <button mat-button [inlineSVG]="'assets/images/icons/icon-upload.svg'"
                                    (click)="fileName.click()"></button><a (click)="fileName.click()">Upload Resume</a>
                            </div>
                            <div class="form-group col-sm-5 col d-flex justify-content-end">
                                <button class="btn btn-primary" (click)="onSubmit()">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</edu-default-layout>