import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'src/app/core/local-storage.service';
import { CoursesDataService } from '../courses/services/courses-data.service';

@Component({
  selector: 'edu-course-category-details',
  templateUrl: './course-category-details.component.html',
  styleUrls: ['./course-category-details.component.scss']
})
export class CourseCategoryDetailsComponent implements OnInit {

  coursesByCategory: any = [];
  rating: any = "unchecked";
  loading: boolean = true;
  courseTitle: string;

  bundleImages: any = [{
    "13946": "assets/images/Bundles/13946.jpeg",
    "13947": "assets/images/Bundles/13947.png",
    "13948": "assets/images/Bundles/13948.jpeg",
    "13949": "assets/images/Bundles/13949.png",
    "552": "assets/images/Bundles/Logistics-101.jpg",
    "562": "assets/images/Bundles/13946.jpeg",
    "204": "assets/images/Bundles/Warehouse-Management.jpg",
    "611": "assets/images/Bundles/Freight-Forwarding-101.jpg",
    "610": "assets/images/Bundles/Freight-Forwarding-201.jpg",
    "206": "assets/images/Bundles/Cold-Chain-Management.jpg",
    "557": "assets/images/Bundles/Picker-Packer.jpg",
    "559": "assets/images/Bundles/OOG-Cargo.jpg",
    "556": "assets/images/Bundles/Safety-Management.jpg",
    "554": "assets/images/Bundles/Warehouse-Safety.jpg",
    "555": "assets/images/Bundles/Human-Factors.jpg",
    "560": "assets/images/Bundles/Covid-19-Prevention.jpg",
    "612": "assets/images/Bundles/Covid-19-Prevention.jpg",
    "613": "assets/images/Bundles/Covid-19-Prevention.jpg",
    "616": "assets/images/Bundles/Safety-Management.jpg", 
    "622": "assets/images/Bundles/Safety-Management.jpg",
    "614": "assets/images/Bundles/Financial-Literacy.jpg",
    "615": "assets/images/Bundles/Financial-Literacy.jpg",
    "623": "assets/images/Bundles/Picker-Packer.jpg",
    "624": "assets/images/Bundles/Picker-Packer.jpg"
  }]

  constructor(
    private coursesDataService: CoursesDataService,
    private route: ActivatedRoute,
    private router: Router,
    public localStorage: LocalStorageService,
    private titleService: Title
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle("Module Category Details - 21CC Recruitment & Training");
    const categoryId = this.route.snapshot.paramMap.get('id');
    this.coursesDataService.getCoursesData().subscribe((allCourses) =>{
      this.coursesByCategory = allCourses.filter((x: any) => x?.isBundle === true && x?.group?.id == categoryId);
      this.courseTitle = this.coursesByCategory[0]?.group?.name;
      this.loading = false;
    });
  }

  addToCart(courseId: string) {
    if (localStorage.getItem('authDetails')) {
      var existingEntries = JSON.parse(localStorage.getItem("shopping-cart"));
      if (existingEntries == null) existingEntries = [];
      var entry = courseId;
      existingEntries.push(entry);
      localStorage.setItem("shopping-cart", JSON.stringify(existingEntries));
      this.coursesDataService.callComponentMethod();
    }
  }

}
