import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BlogService } from '../services/blog.service';
import { LocalStorageService } from 'src/app/core/local-storage.service';

@Component({
  selector: 'edu-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.scss']
})
export class BlogDetailsComponent implements OnInit {

  blog: any;
  detailsArr: any = [];
  loading: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private titleService: Title,
    private blogService: BlogService,
    private router: Router,
    private localStorageService: LocalStorageService,

  ) { }

  ngOnInit(): void {
    // this.titleService.setTitle("Blog Details - 21CC Recruitment & Training");
    const blogUrlCategory = this.route.snapshot.paramMap.get('blogUrlCategory');
    const blogSlug = this.route.snapshot.paramMap.get('blogSlug');
    const blogId = this.route.snapshot.paramMap.get('blogId');

    if(this.localStorageService.retrieveFromLocalStorage('fffaiWai')){
      // url comes from FFFAI or WAI
      const fffaiwai = this.localStorageService.retrieveFromLocalStorage('fffaiWai');
      if(!blogId) {
        this.router.navigate(["/"+fffaiwai+"/blog/" + blogUrlCategory + "/" + blogSlug]);
        const data = blogUrlCategory + "~" + blogSlug;
        this.blogService.getBlogByBlogUrl(data).subscribe((blogData: any) => {
          this.blog = blogData['blogDetails'];
          this.loading = false;
          const title = "Blog - " + this.blog.title;
          this.titleService.setTitle(title);
        });
      }else {
        this.blogService.getBlogById(blogId).subscribe((blogData: any) => {
          this.blog = blogData['blogDetails'];
          const title = "Blog - " + this.blog.title;
          this.titleService.setTitle(title);
          if(blogData?.blogDetails?.blogSlug) {
            if(blogData?.blogDetails?.blogUrlCategory) {
              let urlSlug = `${encodeURIComponent(blogData?.blogDetails?.blogUrlCategory)}/${encodeURIComponent(blogData?.blogDetails?.blogSlug)}`;
                this.router.navigate(["/"+fffaiwai+"blog/" + urlSlug]);
            }else {
              let urlSlug = `${encodeURIComponent(blogData?.blogDetails?.blogSlug)}`;
              this.router.navigate(["/"+fffaiwai+"blog/" + urlSlug]);
            }
          }
          this.loading = false;
        });
      }
    }else{
      // url comes from normal webshop

      if(!blogId) {
        const data = blogUrlCategory + "~" + blogSlug;
        this.blogService.getBlogByBlogUrl(data).subscribe((blogData: any) => {
          this.blog = blogData['blogDetails'];
          this.loading = false;
          const title = "Blog - " + this.blog.title;
          this.titleService.setTitle(title);
        });
      }else {
        this.blogService.getBlogById(blogId).subscribe((blogData: any) => {
          this.blog = blogData['blogDetails'];
          const title = "Blog - " + this.blog.title;
          this.titleService.setTitle(title);
          if(blogData?.blogDetails?.blogSlug) {
            if(blogData?.blogDetails?.blogUrlCategory) {
              let urlSlug = `${encodeURIComponent(blogData?.blogDetails?.blogUrlCategory)}/${encodeURIComponent(blogData?.blogDetails?.blogSlug)}`;
                this.router.navigate(['/blog/' + urlSlug]);
            }else {
              let urlSlug = `${encodeURIComponent(blogData?.blogDetails?.blogSlug)}`;
                this.router.navigate(['/blog/' + urlSlug]);
            }
          }
          this.loading = false;
        });
      }
    }
    
  }

  navToLatestBlog(value: any) {
    this.loading = true;
    if(isNaN(value)) {
      const data = value?.blogUrlCategory + "~" + value?.blogSlug;
      this.blogService.getBlogByBlogUrl(data).subscribe((blogData: any) => {
        this.blog = blogData['blogDetails'];
        if(blogData?.blogDetails?.blogUrlCategory) {
          let urlSlug = `${encodeURIComponent(blogData?.blogDetails?.blogUrlCategory)}/${encodeURIComponent(blogData?.blogDetails?.blogSlug)}`;
          this.router.navigate(['/blog/' + urlSlug]);
        }else {
          let urlSlug = `${encodeURIComponent(blogData?.blogDetails?.blogSlug)}`;
          this.router.navigate(['/blog/' + urlSlug]);
        }
        this.loading = false;
      });
    }else {
      this.blogService.getBlogById(value).subscribe((blogData: any) => {
        this.blog = blogData['blogDetails'];
        if(blogData?.blogDetails?.blogSlug) {
          if(blogData?.blogDetails?.blogUrlCategory) {
            let urlSlug = `${encodeURIComponent(blogData?.blogDetails?.blogUrlCategory)}/${encodeURIComponent(blogData?.blogDetails?.blogSlug)}`;
            this.router.navigate(['/blog/' + urlSlug]);
          }else {
            let urlSlug = `${encodeURIComponent(blogData?.blogDetails?.blogSlug)}`;
            this.router.navigate(['/blog/' + urlSlug]);
          }
        }
        this.loading = false;
      });
    }
  }

}
