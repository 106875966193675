import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { BlogService } from 'src/app/modules/blog/services/blog.service';
import { Router } from '@angular/router';

@Component({
  selector: 'edu-blog-card',
  templateUrl: './blog-card.component.html',
  styleUrls: ['./blog-card.component.scss']
})
export class BlogCardComponent implements OnInit, OnChanges {

  @Input() tabName: string = '';
  blogList: any = [];
  listOfBlog: any = [];
  newBlogList: any = [];
  offset: number = 0;
  totalBlogCount: any;
  showLoadMore: boolean = true;
  fffai: boolean = true;
  wai: boolean = true;
  @Output() loadingEvent = new EventEmitter<boolean>();

  constructor(
    private blogService: BlogService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getAllBlogs();
    if (this.router.url.includes('fffai')) {
      this.fffai = true;
      this.wai =false;
    }else if(this.router.url.includes('wai')){
      this.fffai = false;
      this.wai =true;
    }else{
      this.fffai = false;
      this.wai =false;
    }
  }

  getAllBlogs() {
    this.blogService.getAllBlog(this.offset).subscribe((allBlog: any) => {
      this.totalBlogCount = allBlog.slice(-1)[0];
      this.totalBlogCount = this.totalBlogCount?.totalBlogCount;
      this.blogList = allBlog.slice(0, -1);
      this.newBlogList = this.blogList;
      this.listOfBlog = allBlog.slice(0, -1);
      if (this.blogList.length == this.totalBlogCount) {
        this.showLoadMore = false;
      }
      this.loadingEvent.emit(false);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    switch (this.tabName) {
      case "All":
        this.listOfBlog = this.blogList;
        break;
      case this.tabName:
        this.listOfBlog = [];
        for (let i = 0; i < this.blogList.length; i++) {
          if (this.blogList[i]?.category?.includes(this.tabName)) {
            this.listOfBlog.push(this.blogList[i]);
          }
        }
        break;
      case "Press Release":
        this.listOfBlog = [];
        break;
      default:
        this.listOfBlog = this.blogList;
    }
  }

  updateListOfBlog(allBlog: any) {
    this.listOfBlog = [];
    for (let i = 0; i < allBlog.length; i++) {
      if (this.tabName) {
        if (this.tabName == 'All') {
          this.listOfBlog = this.newBlogList;
        } else {
          if (allBlog[i]?.category?.includes(this.tabName)) {
            this.listOfBlog.push(allBlog[i]);
          }
        }
      } else {
        this.listOfBlog = this.newBlogList;
      }
    }
  }

  loadBlog() {
    this.loadingEvent.emit(true);
    this.offset += 9;
    this.blogService.getAllBlog(this.offset).subscribe((allBlog: any) => {
      this.newBlogList = this.newBlogList.concat(allBlog.slice(0, -1));
      if (this.newBlogList.length == this.totalBlogCount) {
        this.showLoadMore = false;
      }
      this.updateListOfBlog(this.newBlogList);
      this.loadingEvent.emit(false);
    });
  }

}
