import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'src/app/core/local-storage.service';
import { WindowRefService } from 'src/app/shared/window-ref.service';
import { CoursesDataService } from '../courses/services/courses-data.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HighlightTitleService } from 'src/app/shared-layout/highlight-title.service';

@Component({
  selector: 'edu-course-details',
  templateUrl: './course-details.component.html',
  styleUrls: ['./course-details.component.scss']
})
export class CourseDetailsComponent implements OnInit,OnDestroy {

  bundle: any = [];
  bundleCourses: any = [];
  totalTime: number = 0;
  rHours: any = 0;
  rMinutes: any = 0;
  loading: boolean = true;
  avgRating: number = 0;
  finalAvgPercentage: number = 0;
  shoppingCartWithoutBundleId:any;
  shoppingCartWithoutBundleIdResult:any;
  isCoursesFromTeams:any;
  isPurchased:boolean = false;
  isAuthDetails:any;

  constructor(
    private coursesDataService: CoursesDataService,
    private route: ActivatedRoute,
    private router: Router,
    private winRef: WindowRefService,
    public localStorage: LocalStorageService,
    private titleService: Title,
    private sanitizer: DomSanitizer,
    private highlightTitleService: HighlightTitleService
  ) { }

  ngOnInit(): void {
    // this.titleService.setTitle("Course Details - 21CC Recruitment & Training");
    this.isAuthDetails = this.localStorage.retrieveFromLocalStorage("authDetails");
    this.highlightTitleService.setIsSelectPlanActive(true);
    this.shoppingCartWithoutBundleId=localStorage.getItem("shopping_cart_without_bundle_id");

    const courseLang = this.route.snapshot.paramMap.get('courseLang');
    const courseSlug = this.route.snapshot.paramMap.get('courseSlug');
    const courseId = this.route.snapshot.paramMap.get('courseId');
    this.isCoursesFromTeams = this.localStorage.retrieveFromLocalStorage("isCoursesFromTeams");

    if(this.localStorage.retrieveFromLocalStorage("isPurchased") == 'true'){
      this.isPurchased = true;
      // console.log('this.isPurchased')
      // console.log(this.isPurchased)
    }else{
      this.isPurchased = false;
      // console.log('this.isPurchased')
      // console.log(this.isPurchased)
    }

    if(this.isCoursesFromTeams== 'yes'){

      this.coursesDataService.specificPackageID(courseId).subscribe((specificPackage: any) => {
        setTimeout(() => {
          // console.log('-------Specific packae with ID-------');
          // console.log(specificPackage);
          this.bundle = specificPackage.result;
          this.localStorage.storeInLocalStorage("packageId",this.bundle.id);
          for (var i = 0; i < this.bundle?.modules?.length; i++) {
            this.bundleCourses.push(this.bundle.modules[i]);
          };
    
          // console.log('---this.bundleCourses---',this.bundleCourses)
    
          this.totalTime = this.bundle?.modules?.reduce((total, module) => total + module.duration, 0);
    
          this.timeConvert(this.totalTime);          

          const title = "Course - " + this.bundle?.name;
          this.titleService.setTitle(title);
          this.loading = false;


        }, 0);
      });
      
    }else{
      if (!courseId) {
        const data = courseLang + "~" + courseSlug;
        this.coursesDataService.getCourseByCourseUrl(data).subscribe((course) => {
          this.bundle = course;
          for(var i =0; i<this.bundle.modules.length; i++){
            this.bundleCourses.push(this.bundle.modules[i]);
          }
          this.shoppingCartWithoutBundleIdResult=this.shoppingCartWithoutBundleId?.includes(this.bundle?.id);
          // console.log(this.shoppingCartWithoutBundleIdResult)
          const title = "Course - " + this.bundle?.name;
          this.titleService.setTitle(title);
          let urlSlug = `${encodeURIComponent(courseLang)}/${encodeURIComponent(course?.courseSlug)}`;
          this.router.navigate(['/course/' + urlSlug]);
        });
      } else {
        this.coursesDataService.getCourseByCourseId(courseId).subscribe((course: any) => {
          
          this.bundle = course;
          // console.log('---this.bundle---',this.bundle)
          for (var i = 0; i < this.bundle?.modules?.length; i++) {
            this.bundleCourses.push(this.bundle.modules[i]);
          };
    
          // console.log('---this.bundleCourses---',this.bundleCourses)
    
          this.totalTime = this.bundle?.modules?.reduce((total, module) => total + module.duration, 0);
    
          this.timeConvert(this.totalTime);          

          const title = "Course - " + this.bundle?.name;
          this.titleService.setTitle(title);
          if(course?.courseSlug) {
            let urlSlug = `${encodeURIComponent(course?.language)}/${encodeURIComponent(course?.courseSlug)}`;
            this.router.navigate(['/course/' + urlSlug]);
          }
        });
      }
      this.loading = false;
    }


     

  }

  isExpanded(course: any): boolean {
    // Check the aria-expanded attribute for the course
    // Return true if it's "true", otherwise return false
    return course && course['aria-expanded'] === 'true';
  }

  navigateToModuleDetails(moduleDetail){
    this.router.navigate(['/module/'+moduleDetail?.id]);
    // console.log(moduleDetail);
    this.localStorage.storeInLocalStorage("moduleDetail",JSON.stringify(moduleDetail));
  }

  navigateToPlan(){
    if(this.isAuthDetails){
      this.router.navigate(['/admin-registration/manage-learners']);
    }else{
      
    this.router.navigate(['/select-plan']);
    }

  }


  routerLink="/module/{{ course?.id }}"

  getFormattedLanguage(language: string): string {
    if (!language || language.trim() === '') {
      return 'English';
    } else if (language === 'en') {
      return 'English';
    } else if (language === 'hi') {
      return 'हिंदी';
    } else if (language === 'ta') {
      return 'தமிழ்';
    } else {
      return language; // Return the original value if it doesn't match any condition
    }
  }

  totalAvgRating(rateValue: number) {
    this.avgRating += rateValue;
  }

  totalAvgPercentageRatings(totalRatings: number) {
    this.finalAvgPercentage = (this.avgRating / totalRatings) * 20;
  }

  sanitizeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  
  timeConvert(n: any) {
    var num = n;
    var hours = (num / 60);
    this.rHours = Math.floor(hours);
    var minutes = (hours - Math.floor(hours)) * 60;
    this.rMinutes = Math.round(minutes);
  }

  addToCart(courseId: string) {
      var existingEntries = JSON.parse(localStorage.getItem("shopping-cart"));
      if (existingEntries == null) existingEntries = [];
      var entry = courseId;
      existingEntries.push(entry);
      localStorage.setItem("shopping-cart", JSON.stringify(existingEntries));
      var existingEntries = JSON.parse(localStorage.getItem("shopping-cart"));
      this.coursesDataService.callComponentMethod();
  }

  createRzpayOrder(data) {
    if (!localStorage.getItem('shopping-cart')?.includes(this.bundle?.id)) this.addToCart(this.bundle?.id);
    this.router.navigateByUrl('/checkout');
    // call api to create order_id
    //this.payWithRazor(order_id);
  }

  payWithRazor(val) {
    const options: any = {
      key: 'rzp_live_6fBtcEXoe2DS9O',
      secret: 'H6CBbjMpb6dd4y3PQUqzuin3',
      amount: 125500, // amount should be in paise format to display Rs 1255 without decimal point
      currency: 'INR',
      name: '', // company name or product name
      description: '',  // product description
      image: './assets/logo.png', // company logo or product image
      order_id: val, // order_id created by you in backend
      modal: {
        // We should prevent closing of the form when esc key is pressed.
        escape: false,
      },
      notes: {
        // include notes if any
      },
      theme: {
        color: '#0c238a'
      }
    };
    options.handler = ((response, error) => {
      options.response = response;
      console.log(response);
      console.log(options);
      // call your backend api to verify payment signature & capture transaction
    });
    options.modal.ondismiss = (() => {
      // handle the case when user closes the form while transaction is in progress
      console.log('Transaction cancelled.');
    });
    const rzp = new this.winRef.nativeWindow.Razorpay(options);
    rzp.open();
  }

  ngOnDestroy(): void {
    this.highlightTitleService.setIsSelectPlanActive(false);
  }

}
